import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory
import axios from 'axios';
import '../styles/mystyles.css'
import '../App.css' // Import the CSS file
import { Container } from 'react-bootstrap';
import { Form, Col, Row, OverlayTrigger, Tooltip, Alert, Button } from 'react-bootstrap';


const ResetPassword = () => {
  const { uidb64, token } = useParams(); // Extract uidb64 and token from the URL
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // useNavigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (newPassword1 !== newPassword2) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('/api/password-reset-confirm/', {
        uidb64,
        token,
        new_password1: newPassword1,
        new_password2: newPassword2,
      });
      setMessage('Password has been reset successfully.');

      // Redirect to login page after successful reset
      setTimeout(() => {
        // navigate('/'); // use this until we add back dedicated login page (to suplement modal)
        // navigate('/login'); // use navigate instead of history.push()
        try {
          window.close();
        } catch (e) {
          setMessage('You can now manually close this tab.');
        }
      }, 2000); // Redirect after 3 seconds
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.detail);
      } else {
        setError('An error occurred, please try again.');
      }
    }
  };

  return (
    <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
      <div className="row">
      <div className="col-sm-2"></div>
      <div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
      <h2>Reset Your Password</h2>
      {message && <Alert variant="success">{message}</Alert>}
{error && <Alert variant="danger">{error}</Alert>}

<Form onSubmit={handleSubmit}>
  <Row>
    <Form.Group>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`tooltip-right`}>Enter your new password</Tooltip>}
      >
        <Form.Label htmlFor="newPassword1" className="fw-light">New Password:</Form.Label>
      </OverlayTrigger>
      <Form.Control
        type="password"
        name="newPassword1"
        id="newPassword1"
        value={newPassword1}
        onChange={(e) => setNewPassword1(e.target.value)}
        className="form-control bg-secondary border-secondary text-light"
        autoComplete="off"
        required
      />
    </Form.Group>

    <Form.Group>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`tooltip-right`}>Confirm your new password</Tooltip>}
      >
        <Form.Label htmlFor="newPassword2" className="fw-light">Confirm New Password:</Form.Label>
      </OverlayTrigger>
      <Form.Control
        type="password"
        name="newPassword2"
        id="newPassword2"
        value={newPassword2}
        onChange={(e) => setNewPassword2(e.target.value)}
        className="form-control bg-secondary border-secondary text-light"
        autoComplete="off"
        required
      />
    </Form.Group>
  </Row>

  <Button type="submit" className="mt-3">Reset Password</Button>
</Form>
      </div>
      <div className="col-sm-2"></div>
      </div>
    </Container>
  );
};

export default ResetPassword;