import { useState } from 'react';
import { useAuthStore } from '../store/auth'; // Assuming you're using Zustand or similar for state management
import useAxios from './useAxios'; // Your custom Axios hook
import Cookies from 'js-cookie'; // If you're using cookies to manage tokens

function useLogout() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleShowLogin = () => setShowLoginModal(true);
  const handleCloseLogin = () => setShowLoginModal(false);
  const api = useAxios();

  // Define the logout function inside the hook
  const logout = async () => {
    try {
      const response = await api.post('logout/', {});
    } catch (error) {
      console.error('Logout failed: ', error);
    } finally {
      // This will always run, regardless of the try/catch result
      // window.location.reload();
      // Clear user state, cookies, or any other session-related data
      Cookies.remove('access');
      Cookies.remove('refresh');
      useAuthStore.getState().setUser(null); // Clear user from global state
    }
  };

  return { logout }; // Return the function from the custom hook
}

export default useLogout;


