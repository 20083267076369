import React, { useEffect, useState, Component } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
  
function FormTemplate(props) {

  return (
    <div>
      <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>{props.overlaytext}</Tooltip>}>
        <Form.Label htmlFor={props.formname} className="fw-light small">{props.formlabel}</Form.Label>
      </OverlayTrigger>
      <Form.Control
        type={props.type}
        name={props.formname}
        id={props.formname}
        value={props.presetObject[props.formname]} // Dynamically access the value
        onChange={(e) => props.handleObjectChange(props.formname, e.target.value)} // Use formname as a dynamic key
        className="form-control border-secondary"
        autoComplete="off"
      ></Form.Control>
      {props.errors[props.formname] && <Alert className="alert alert-danger">{props.errors[props.formname]}</Alert>}
    </div>
  );
}

export default FormTemplate;
