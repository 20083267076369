import React, { useEffect, useState, useRef, Component } from 'react'
import useAxios from '../utils/useAxios';
import ValidateForm from "../components/validation.js"
import ValidateIncomeForm from "../components/validation_income_ladder.js"
import validatesanserrorsForm from "../components/validationsanserrors.js"
import PlanButtons from "../components/planbuttons.js";
import ReLoginForm from '../views/relogin';
import { usePresetStore } from "../components/datastore.js";
import { useAuthStore } from '../store/auth'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal, ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import '../styles/mystyles.css'
import '../App.css' // Import the CSS file
import { FaInfoCircle, FaDownload } from 'react-icons/fa'; 
import { useLocation } from 'react-router-dom';

function APITestPage() {
    const tempvar = false

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    useEffect(() => {
      const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
      };
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
    }, []);

    const webpageData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Retire Smart Calc",
      "logo": "https://www.retiresmartcalc.com/logo.png",
      "description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
    };
    
    const api = useAxios();  // Ensure this is at the top level of your component or function

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [latestVersion, setLatestVersion] = useState(null);
    const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.post('/getinflationdata/', {'first_date': '2023-10-01', 'second_date': '2024-10-01', 'currency': presetObject.currency_set});
          console.log(response.data)
          // const versions = response.data.items.map(item => item.version);
          // const maxVersion = Math.max(...versions);
          // setLatestVersion(maxVersion);
          // console.log(maxVersion);
          // setData(response.data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, []);

    return (
      <>
        <Helmet>
          <title>Retire Smart Calc - Retirement Portfolio Simulation Calculator</title>
          <meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
          <link rel="canonical" href="https://www.retiresmartcalc.com/#/calc"></link>
          <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
          <script type="application/ld+json">
          {JSON.stringify(webpageData)}
          </script>
        </Helmet>
    
        <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        <h3>Data</h3>
        {/* {data ? <p>{data}</p> : <p>Loading...</p>} */}
				</Container>
      </>
    )
};

export default APITestPage

