import React, { useEffect, useState, Component } from 'react'
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; 

function FloatingColumnChart(datafeed) {

  // function isMobileDevice() {
  //   return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  // };

  // function setAspectRatio() {
  //   var aspectRatio = isMobileDevice() ? false : true; // Adjust the values as needed text
  //   return aspectRatio;
  // };

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * datafeed.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);


  const chartdata = datafeed.data
  const data = {
    labels: ['0-10%', '10-20%', '20-30%', '30-40%', '40-50%', '50-60%', '60-70%', '70-80%', '80-90%', '90-100%'],
    datasets: [
      {
        label: 'Range',
        data: [
          { x: '0-10%', y: [chartdata[0], chartdata[1]]},
          { x: '10-20%', y: [chartdata[1], chartdata[2]] },
          { x: '20-30%', y: [chartdata[2], chartdata[3]] },
          { x: '30-40%', y: [chartdata[3], chartdata[4]] },
          { x: '40-50%', y: [chartdata[4], chartdata[5]] },
          { x: '50-60%', y: [chartdata[5], chartdata[6]] },
          { x: '60-70%', y: [chartdata[6], chartdata[7]] },
          { x: '70-80%', y: [chartdata[7], chartdata[8]] },
          { x: '80-90%', y: [chartdata[8], chartdata[9]] },
          { x: '90-100%', y: [chartdata[9], chartdata[10]] },
        ],
        backgroundColor: 'rgba(75, 192, 192, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'x',
    responsive: true,
    // maintainAspectRatio: setAspectRatio(),
    // maintainAspectRatio: isLargeScreen,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: true,
        color: 'white',
        font: {
          size: 14, // Set the font size for the title
        },       
        text: 'Distribution of final portfolio value from all back-test simulation cycles ($,£ current value)',
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        stacked: true,
        title: {
          display: true,
          text: 'Frequency deciles',
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white'
        },
      },
      y: {
        stacked: true,
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          beginAtZero: true,
          color: 'white',
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
            else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
            else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
            else {return '' + value.toString()};
          },
        },    
      },
    },
  };

  return (
    // <div style = {{ width:'100%', height:'300px'}}>
    //   {/* <h5>Distribution by decile of final portfolio value:</h5> */}
    //   <Bar data={data} options={options} />
    // </div>

    <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Bar data={data} options={options} />
      </div>
    ) : (
      <div style={{ width: '100%', height: `${chartHeight}px` }}>
        <Bar data={data} options={options} />
      </div>
    )}
    </div>

  );
};

export default FloatingColumnChart;


