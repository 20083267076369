import React, { useEffect, useState, Component } from 'react'
import { Dropdown } from 'react-bootstrap';
  
function PlanButtons(props) { 

  return ( 
    <div> 
          {props.logInState ? (
            <>
              <Dropdown>
                <Dropdown.Toggle variant="btn btn-success" id="dropdown-basic" style={{ marginTop: '8px', marginBottom: '8px' }}>
                  Manage Plan
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={props.getPlans}>Retrieve plan</Dropdown.Item>
                  {props.planAlreadySaved && (
                    <Dropdown.Item onClick={props.savePlan}>Save as existing plan</Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={props.copyPlan}>Save as new plan</Dropdown.Item>
                  <Dropdown.Item onClick={props.openResetModal}>Reset plan</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <button className="btn btn-success" onClick={props.openResetModal} style={{ marginTop: '8px', marginBottom: '8px' }}>
                Reset Plan
              </button>
            </>
          )}
    </div> 
  ); 
} 
  
export default PlanButtons; 
