import React, { useEffect, useState, Component } from 'react'
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png"


import '../styles/mystyles.css'

function About() {

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Define the structured data object which feeds the thing inside the script tag below.  Apparently convention to include the script tag even though not strictly necessary.
	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	return (
	<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>

	<Helmet>
		<title>Retire Smart Calc - Introduction, Explanation of Key Features, Methodology and Data Sources</title>
		{/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
		<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
		<link rel="canonical" href="https://www.retiresmartcalc.com"></link>
		<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity. " />

		<script type="application/ld+json">
      	{JSON.stringify(webpageData)}
    	</script>
	</Helmet>


	<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
	<Col xs={0} sm={1} />
		<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			{/* <h2 className="lead">Key features:</h2>
			<hr className="my-1"></hr>
			{/* <li className={isSmallScreen ? 'small' : ''}>Plan contribution and withdrawal schedules across lifecycle</li> */}
			{/* <li className={isSmallScreen ? 'small' : ''}>Calculate and back-test safe withdrawal rates (SWR) for a retirement portfolio</li> */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Select between USD and GBP base currency data sets (for US and UK based investors respectively)</li>   */}
			{/* <li className={isSmallScreen ? 'small' : ''}>Incorporate inflation linked bonds as well as conventional bonds and equities in portfolio allocation</li>  */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Find optimal portfolio asset mix to balance portfolio stability vs. potential returns</li>             */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Compare drawdown vs. annuity based retirement income strategies</li>  */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Examine constant vs. flexible withdrawal strategies</li> */}
            {/* <br></br> */}
            <h2 className="lead">Methodology - extension of Bengen model</h2>
            <hr className="my-1"></hr>
			{/* <p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc is a tool designed to help people manage both building up and spending down a retirement portfolio across the different phases of their lifecycle. This includes optimising both the level of contributions that they make to the portfolio and the level and timing of retirement income they take from that portfolio, as well as the portfolio’s asset allocation mix.</p> */}
            <p className={isSmallScreen ? 'small' : ''}>As its base, RetireSmartCalc uses the <a className="text-info" href="https://en.wikipedia.org/wiki/William_Bengen" target="_blank">Bengen</a> or exploratory simulation approach (named after William Bengen who devised his 4% rule from it) to allow the user to test whether a retirement portfolio of a given size should last for a given number of years for a given schedule of annual withdrawals. The approach uses historical equity return, bond return and inflation data to simulate how a retirement portfolio strategy would have fared had retirement started onwards from 1925, then from 1926, then from 1927, and so on. It produces a distribution of portfolio values both throughout and at the end of the simulation. It calculates in what percentage of back-testing cycles the portfolio would have lasted until the end of the simulation period (the success rate) and, conversely, in what portion of cycles it would have been depleted before the end of the simulation period (the failure rate).</p>
			<p className={isSmallScreen ? 'small' : ''}>A benefit of using this approach with historic data is it purposely avoids that need for ‘crystal ball’ judgement on future asset returns. However the historic data that is used needs to both go back far enough and be broad enough to cover the range of economic and political events that can influence asset returns.  Plans that have been back-tested through a sufficiently long set of historic data will rest on a conservative scenario of asset returns (i.e. the least positive cases in the historical data). This is safer than planning on the basis of average asset returns, because in ~50% cases, the investor does not achieve the average return.</p>
			<p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc extends on the Bengen approach in a number of ways:</p>
			<p className={isSmallScreen ? 'small' : ''}>1) It allows the user to use a longer set of historic data going back earlier than 1925 and extending through to end 2023.  This improves the robustness of the back-testing by incorporating two extended periods of poor market returns (in real terms) - the first during the 1910’s and the First World War, the second during the 1970’s and the energy crisis.  Both these periods experienced high inflation which negatively impacted real returns.  The user can also choose circular or linear exploratory simulation.  Circular exploratory simulation extends the number of back-testing cycles by allowing those cycles that begin with a start year towards the end of the historic data set to ‘circle’ round and also use data from the beginning of the historic data set to complete the cycle.</p>
			<p className={isSmallScreen ? 'small' : ''}>2) Thanks of having more back-testing cycles, it offers extended simulations that can cover an entire lifecycle and include a contributing (accumulation) phase before the withdrawal (decumulation) phase.  The tool can be used to test the required level of contribution to achieve a target retirement portfolio size and subsequent withdrawal schedule, as well as test a schedule of withdrawals for a given retirement portfolio size.</p>
			<p className={isSmallScreen ? 'small' : ''}>3) It allows the user to choose whether to use US domestic or Global equity returns in the back-testing data.  Again this can improve the robustness of back-testing given that US market returns over the past 120 years can be considered ‘exceptional’ in a comparison with other markets. Global equity returns, particularly during the years 1900-1945, may better incorporate the impact of war, uncontrolled inflation and political upheaval on market returns. UK based users also have the option of selecting a data-set that consists of Global equity returns in GBP terms, UK bonds and UK inflation.</p>
			<p className={isSmallScreen ? 'small' : ''}>4) It gives the user the option to i) use an entirely historic back-testing data-set or ii) to use current forward-looking market yields to simulate returns on the bond portion of the portfolio together with historic data to simulate returns on the equity portion of the portfolio. The second option allows for both conventional and inflation linked government bonds to be simulated in the portfolio asset mix.  Since there is more limited historic data for inflation linked bonds (the US and UK governments only started issuing them in the 1980’s), it is not possible to include them in an entirely historic back-testing data-set. The forward-looking market yield data in RetireSmartCalc is updated weekly - see beneath the chart of bond market yields on the SWR Observations page to check when the current data is from.</p>
			<p className={isSmallScreen ? 'small' : ''}>5) It allows the user the option to simulate purchasing a fixed (immediate) annuity in their retirement portfolio, either at the beginning of the simulation cycle or during the cycle after a number of years of retirement. This, together with varying the asset mix between equities, conventional bonds and inflation linked bonds, allows the user to compare and contrast different portfolio strategies looking at the amounts that can be safely withdrawn each year (the safe withdrawal rate) versus the potential for portfolio growth that can provide for increased future withdrawals or a larger bequest at the end of the simulation cycle. The user will need to research current market annuity prices and input these into the calculator, as these do not yet feed automatically into RetireSmartCalc.</p>	
			<p className={isSmallScreen ? 'small' : ''}>6) it allows the user to back-test flexible, rather than just fixed, withdrawal strategies.  This includes flexing the withdrawal level up or down in response to market conditions or taking an extra bonus withdrawal when and if the investment portfolio has grown in response to positive market returns such that the portfolio becomes larger than it needs to be to meet all future scheduled withdrawals. Other flexible withdrawal options offered by the tool include the Yale Endowment and Vanguard Dynamic Spending approaches.</p>
			<p className={isSmallScreen ? 'small' : ''}>7) it can incorporate the impact of taxes and portfolio management fees. See below.</p>
			<p className={isSmallScreen ? 'small' : ''}>All the values in the simulation calculator are inflation adjusted and are shown in current value terms (today's money).</p>
            <h2 className="lead">Methodology - approach explanation</h2>
            <hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>If the linear exploratory approach is selected, the number of back-testing cycles run in each simulation depends on i) the length of the simulation (years) and ii) the number of years of historic data. E.g. if the simulation length is 30 years and the US data set from 1870-2023 is selected, then the simulation model will run 153 - 30 = 123 back-testing cycles. The first cycle will use the 1870-1900 subset of the data, the second cycle will use the 1871-1901 subset and so on until the last cycle uses the 1993-2023 subset. If the circular exploratory approach is selected, the number of back-testing cycles in each simulation with be the number of years of historic data. Using the same example, those back-testing cycles that start after 1993 will use the remaining years of the historic data-set up to 2023 and then circle back to the beginning of the data-set with the years from 1870 to get a total of 30 years.</p>
			<p className={isSmallScreen ? 'small' : ''}>The advantage of using the circular exploratory approach is that it better ensures the inclusion of back-testing cycles that begin with those years exhibiting poor real-term returns (also known as sequence risk). E.g. using the linear approach, the last starting year for a 70 year simulation would be 1953. That would miss out on back-testing with years starting in the late 1960’s which provide more sequence risk. The circular approach solves this. The circular approach also avoids the linear approach problem of over-sampling years in the middle of the historic dataset period and under-sampling years at the beginning and the end.</p>
			<p className={isSmallScreen ? 'small' : ''}>Each back-testing cycle starts by deducting the withdrawal amount for year 1 from (or adding the contribution amount for year 1 to) the starting asset portfolio value and then applying the real rate of return for year 1 to the adjusted value. Next the withdrawal amount for year 2 is deducted (or the contribution amount is added) and the real rate of return for year 2 is applied to that value. And so on until the end of the cycle. The simulation model uses year on year data e.g. each one year return is calculated by looking at the index value at the end of 1871 vs. the end of 1870 and adding the dividend return where appropriate. This means that any intra year volatility may not be reflected in the asset portfolio value simulation but this is less of an issue in the context of using the model to test multi-year strategies.</p>
			<p className={isSmallScreen ? 'small' : ''}>If during each back-testing cycle the asset portfolio value is fully depleted (i.e. there are insuﬃcient funds left to pay for the remaining withdrawal amounts) then a fail is registered. The backtest simulation cycle failure rate = number of cycles that fail / total number of cycles. The success rate is 100% minus the failure rate. However this number does not distinguish at which stage of the simulation the failure happens (e.g. failures earlier on in the simulation are a worse outcome than those nearer the end) so it should be used in conjunction with an analysis of the simulated income / withdrawal distribution. The model does not allow the asset portfolio value to go negative.</p>
			<p className={isSmallScreen ? 'small' : ''}>The model uses real rates of asset returns (i.e. adjusted using either US or UK historic inflation) which means the portfolio value, contribution and withdrawal amounts are eﬀectively held in current value terms through the simulation. The alternative would be to use nominal rates of return, then increase the contribution and withdrawal amounts each year for inflation and finally discount the end portfolio value back to current value terms using the inflation rate over the entire length of the simulation. The model output would be the same but it would be more complex to compute.</p>
			<p className={isSmallScreen ? 'small' : ''}>Overall the robustness of the simulation model is dependent on the historical dataset providing a good representation of possible future returns. We know that there is no guarantee that this will be true and this is why it is important that the chosen historical dataset includes suﬃciently long periods of market stress and negative real returns.</p>
			<p className={isSmallScreen ? 'small' : ''}>If the option is taken to use forward-looking market yields to simulate returns on the bond portion of the portfolio, then the simulation model still runs the same number of back-testing cycles with the same sequence of historic equity and inflation datasets as before. In the first year of the cycle it will use year 1 from the historic equity dataset and year 1 from the market forward yield data. In the second year of the same cycle it will use year 2 from the historic equity dataset and year 2 from the market forward yield data (e.g. the 1 year yield in 1 year’s time). If the simulation model is calculating the return for a conventional bond it will make an inflation adjustment using the historic inflation dataset in order to make the return real. If the model is calculating the return for an index linked bond then the forward yield is already taken as a premium (or discount) to actual inflation and so no further adjustment is required. This approach assumes the bonds in the investment portfolio are maturity matched against the withdrawals they will finance. If this is not the case then this approach may underestimate the risk of bond value volatility or re-investment risk in the simulated outcomes.</p>
			<p className={isSmallScreen ? 'small' : ''}>To calculate bond returns when using historic data, the simulation model uses a historic dataset of 10 year government bond yields. It calculates the year on year return as a combination of the change in capital value of a 10 year bond (if/as the bond yield changes during the year) plus the prevailing interest rate yield on the bond at the beginning of the year on year period. When calculating bond returns using forward-looking market yields, the simulation model simply uses that yield to give the return. The user has the option to add a net spread to bond returns to simulate investing in corporate rather than government bonds. This spread can be added to conventional bonds only (if using forward looking market yields; to simulate holding a portfolio of corporate bonds and index linked government bonds) or to all bond returns (either historical or forward looking market yields). The user should use a credit loss adjusted spread, rather than just the gross market credit spread. The asset portfolio value simulation will not capture any change in credit spreads or unexpected credit losses (over those priced into the credit loss adjusted spread). The user can also add a spread to equity returns to simulate 'alpha'. The user can enter a portfolio fee to cover management, advisory and/or transaction fees. The simulation model deducts this fee from both equity and bond returns before tax.</p>
			
			<p className={isSmallScreen ? 'small' : ''}>The simulation model includes an algorithm to calculate the minimum safe withdrawal level. The safe funding level gives the asset portfolio value required to cover all scheduled withdrawals in all back-testing scenarios for a given asset mix. The algorithm works by running a series of simulations. The first is for the full length of the simulation and it iterates the simulation with an increasing starting asset portfolio amount until it gets to the end of the simulation without exhausting the portfolio. That portfolio amount becomes the safe funding level. It then runs the iteration again but for a simulation length that is a year shorter, and so on until it has the full curve of safe funding levels at each year through the simulation. From this minimum safe funding level curve, the simulation model also calculates a maximum safe withdrawal rate. The safe funding level curve is used by the model to guide the 'constant with bonus’ withdrawal strategy. As each year passes in the simulation, if the portfolio value is above the safe portfolio funding level then a bonus withdrawal can be paid out.</p>
			<h2 className="lead">Methodology - taxes</h2>
			<hr className="my-1"></hr>   
			<p className={isSmallScreen ? 'small' : ''}>Simulation results are shown net of taxes (if tax rates are entered by the user). Taxes are deducted according to account type in the asset portfolio and the user can enter different tax rates for different account types:</p>
			<ul>
				<li className={isSmallScreen ? 'small' : ''}>For general accounts, taxes are taken from asset portfolio returns. The user can enter seperate rates for equity and bond returns. This deduction covers both capital gains and dividend/interest income taxes. In the case that the user is subject to different rates for capital gains tax and dividend/interest income tax, the user will need to work out an average tax rate and enter that. The user can also choose whether or not to apply the bond return tax rate to the inflation adjustment component of index linked bonds (where there user has selected to use forward market implied bond returns). </li>
				<li className={isSmallScreen ? 'small' : ''}>For tax deferred accounts, taxes are taken on withdrawals from the asset portfolio.</li>
				<li className={isSmallScreen ? 'small' : ''}>For tax exempt account, no taxes are taken.</li>
			</ul>
			<p className={isSmallScreen ? 'small' : ''}>The simulation assumes that general accounts are drawn down first, then tax-exempt accounts and finally tax-deferred accounts.</p>
			<p className={isSmallScreen ? 'small' : ''}>The user can enter tax rates for state pension income, occupational pension income and annuity income. In the case of annuity income, the simulation assumes that the 'return of principal' component of annuity income is not subject to taxation and only applies the tax rate to the 'interest return' component of annuity income. The simulation calculates the 'return on principal' component according to the user's age when the annuity is purchased and life expectancy data (see data source below). For example, if an annuity is purchased for 100,000 at the age of 75 and life expectancy (at 75) is 85 then the first 10,000 of annuity income each year is treated as 'return of principal' and not subject to tax.</p>
			<p className={isSmallScreen ? 'small' : ''}>In the case that the user is simulating taking annuity income from a tax deferred pension account, they should enter the same tax rate for tax deferred withdrawals and annuity interest rate return component.</p>
			<h2 className="lead">Data sources</h2>
            <hr className="my-1"></hr>          
            <p className={isSmallScreen ? 'small' : ''}>Different data sets can be selected in the simulation calculator. US data sets use either US or global equity returns together with US bond returns and US CPI data. UK data sets use global equity returns (in GBP) together with UK bond returns and UK CPI data.</p>   
			<p className={isSmallScreen ? 'small' : ''}>US equities are represented by S&P 500 total returns data from Robert Shiller. Historic US bond yields and US CPI data are also from <a className="text-info" href="https://www.multpl.com" target="_blank">Robert Shiller</a>. Historic UK bond yields and UK CPI data from the <a className="text-info" href="https://www.bankofengland.co.uk/statistics/research-datasets" target="_blank">Bank of England</a>.  Global equities up until 1960 are represented by a composite total return benchmark including US, UK, France and Germany (representing the largest four equity markets in 1900 - US data is from Robert Shiller, UK data is from Barclays Equity Gilt study, FR data is from David Le Bris and Pierre-Cyrille Hautcouer and DE data is from Ulrich Ronge). Global equities from 1960-1979 uses data from Ibbotson, Carr and Robinson and includes 19 countries (covering major markets in North America, Western Europe and the Far East). Global equities from 1980 is represented by a composite total return benchmark combining US S&P 500 and MSCI World ex-US indicies. Current US market bond yields can be found at <a className="text-info" href="https://fred.stlouisfed.org/" target="_blank">St Louis Federal Reserve</a> and UK market bond yields can be found at <a className="text-info" href="https://www.bankofengland.co.uk/statistics/yield-curves" target="_blank">Bank of England</a>.</p>
			<p className={isSmallScreen ? 'small' : ''}>The historic data set used by RetireSmartCalc can be <a href="/downloadhistoricdata" download>downloaded here</a> and the forward data set can be <a href="/downloadforwarddata" download>downloaded here</a>. The composition and calculation of the historic global equity return index can be <a href="/downloaddata" download>downloaded here</a>. Mortality data is taken from Idzorek and Kaplan: Lifetime Financial Advice and can be <a href="/downloadmortalitydata" download>downloaded here</a>.</p>
			{/* <p className={isSmallScreen ? 'small' : ''}>The model and API documentation can be <a href="/downloaddocumentation" download>downloaded here</a>.</p> */}
			{/* <h2 className="lead">Get in touch:</h2>
            <hr className="my-1"></hr>
            <p className={isSmallScreen ? 'small' : ''}>This is a beta testing release. Please get in touch with any feedback or improvement ideas: contact@retiresmartcalc.com</p> */}
		</Col>
	<Col xs={0} sm={1} />
	</Row>
	</Container>
	);
}; 

export default About;

