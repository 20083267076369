import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Modal, Button, Form, Image } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png";
import '../styles/mystyles.css';
import { FaChartPie, FaUser, FaBriefcase, FaClock, FaDollarSign, FaGifts, FaBirthdayCake, FaMoneyCheckAlt, FaQuestion, FaEnvelope, FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { usePresetStore } from "../components/datastore.js";
import ScreenShot from "../components/Screenshot1.png";

function Intro() {
	const navigate = useNavigate();
	const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
	const [equityAllocation, setEquityAllocation] = useState(70)
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	const [showModal, setShowModal] = useState(false);
	const [showRedirectModal, setShowRedirectModal] = useState(false);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [responses, setResponses] = useState({
		question1: '',
		question2: null,
		question3: '',
		question4: null,
		question5: '',
		question6: null,
		question7: null,
		question8: '',
		question9: null,
		question10: null,
		question11: '',
		question12: null,
		question13: null,
		question14: '',
		question15: null,
		question16: null,
		question17: '',
		question18: null,
	});

	const resetResponseFields = () => {
		setResponses({
		  question1: '',  // Corrected to use string keys
		  question2: null,
		  question3: '',
		  question4: null,
		  question5: '',
		  question6: null,
		  question7: null,
		  question8: '',
		  question9: null,
		  question10: null,
		  question11: '',
		  question12: null,
		  question13: null,
		  question14: '',
		  question15: null,
		  question16: null,
		  question17: '',
		  question18: null,
		});
	  };
	
	const [errors, setErrors] = useState({
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		question5: '',
		question6: '',
		question7: '',
		question8: '',
		question9: '',
		question10: '',
		question11: '',
		question12: '',
		question13: '',
		question14: '',
		question15: '',
		question16: '',
		question17: '',
		question18: '',
	});

	// Array of questions with their type (text or yes/no)
	const questions = [
		{ question: "Are you a UK or US investor?", type: "radio", options: ['UK', 'US'], validation: (input) => input === 'UK' || input === 'US' },
		{ question: "What is your age?", type: "number", validation: (input) => !isNaN(input) && input >= 0 && input <= 100 },
		{ question: "Are you retired yet?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
		{
			question: "At what age do you plan to retire?", 
			type: "number", 
			validation: (input, responses) => {
				if (typeof responses['question2'] === 'undefined' || responses['question2'] === '') {return false;}
				const currentAge = parseInt(responses['question2'], 10); 
				return !isNaN(input) && input > currentAge && input <= 100;
			}, 
			condition: (responses) => responses['question3'] === 'No'
		},
		{ question: "Do you expect to receive a state pension / social security?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
		{ question: "How much do you expect to receive (per year, in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question5'] === 'Yes'},
		{ question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question5'] === 'Yes' },
		{ question: "Do you expect to receive a (defined benefit) occupational pension?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
		{ question: "How much do you expect to receive (per year, in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question8'] === 'Yes'},
		{ question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question8'] === 'Yes' },
		{ question: "Do you have retirement savings (e.g. defined contribution pension fund)? (Note: do not include any property that you plan to sell - this will be captured in another question)", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
		{ question: "How much do you currently have?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question11'] === 'Yes' },
		{ question: "How much are you saving into your retirement savings each year?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question3'] === 'No' },
		{ question: "Do you expect any other one-off inflows into your retirement savings (e.g. future property sale)?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
		{ question: "How much do you expect to receive (in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question14'] === 'Yes' },
		{ question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question14'] === 'Yes' },
		{ question: "How are your retirement savings invested?", type: "radio", options: [
		  'Mostly equities - for higher returns with higher volatility',
		  'Balanced equities and bonds - for moderated volatility'
		], validation: (input) => input === 'Mostly equities - for higher returns with higher volatility' || input === 'Balanced equities and bonds - for moderated volatility', condition: (responses) => responses['question11'] === 'Yes' || responses['question13'] > 0} || responses['question14'] > 0,
		{ question: "How much do you plan to spend each year in retirement (in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0 }
	  ];

	useEffect(() => {
		responses['question17'] === 'Mostly equities - for higher returns with higher volatility' ? setEquityAllocation(70) : setEquityAllocation(40)
	}, [responses['question17']])

	// Functions to show and hide the modal
	const handleShow = () => setShowModal(true);
	const handleClose = () => 
		{
			setQuestionIndex(0);
			resetResponseFields();
			setShowModal(false);
		}
	const handleRedirectShow = () => setShowRedirectModal(true);
	const handleRedirectClose = () => 
		{
			setShowRedirectModal(false);
			resetPresetObject();

			const retireyear = responses['question4'] ? parseInt(responses['question4'], 10) : parseInt(responses['question2'], 10);		
			setPresetObject({
				currency_set: responses['question1'] === 'US' ? 'USD' : 'GBP',
				geographic_set: responses['question1'] === 'US' ? 'DOMESTIC' : 'GLOBAL',
				data_direction: "back",
				start_simulation_age: parseInt(responses['question2'], 10),
				end_simulation_age: 100,
				asset_mix_equity: equityAllocation,
				asset_mix_bond: 100 - equityAllocation,
				asset_mix_index_bond: 0,
				equity_tax: 0,
				bond_tax: 0,
				draw_tax: 0,
				annuity_tax_rate: 0,
				annuity_tax_rate2: 0,
				annuity_tax_rate3: 0,
				fees: 0,
				apply_tax_to_inflation: responses['question1'] === 'US' ? '1' : '0',
			});
			if (responses['question6']) {
				setPresetObject({
					state_pension: [{name: 'State pension / social security', year: parseInt(responses['question7'], 10), amount: responses['question6'], index: '1', increase: 0, last_update_date: '2024-01-01'}]
				});}
			if (responses['question9']) {
				setPresetObject({
					occupational_pension: [{name: 'Occupational pension', year: parseInt(responses['question10'], 10), amount: responses['question9'], index: '1', increase: 0, last_update_date: '2024-01-01'}]
			});}
			if (responses['question12']) {
				setPresetObject({
				  	asset_portfolio: [{name: 'Retirement savings', type: '2', amount: responses['question12'], equity: equityAllocation, bonds: 100 - equityAllocation, index: 0, last_update_date: '2024-01-01'}]
				});}
			if (responses['question13']) {
				setPresetObject({
					contribution_inflows: [{name: 'Contribution to savings', year: parseInt(responses['question2'], 10), yearend: retireyear, amount: responses['question13'], index: '1', increase: 0, type: '2', last_update_date: '2024-01-01'}]
				});}
			if (responses['question15']) {
				setPresetObject({
					extraordinary_inflows: [{name: 'One-off inflow', year: parseInt(responses['question16'], 10), amount: parseInt(responses['question15'], 10), index: '1', increase: 0, type: '0', last_update_date: '2024-01-01'}]
				});}
			if (responses['question18']) {
				setPresetObject({
					income_requirements: [{name: 'Expenditure in retirement', single_year: 0, year: retireyear, yearend: 100, date_start: '2024-01-01', date_end: '2024-01-01', amount: responses['question18'], index: '1', increase: 0, increase2: 0, last_update_date: '2024-01-01', percent_covered: '100', january: '0', february: '0', march: '0', april: '0', may: '0', june: '0', july: '0', august: '0', september: '0', october: '0', november: '0', december: '0'}]
				});}
			navigate('/calc');
			// navigate('/calc', { state: { triggerSubmit: true } });
		}

	const handleNext = () => {
		const currentQuestion = questions[questionIndex];
		const response = responses[`question${questionIndex + 1}`];
	
		// Pass responses explicitly to the validation function
		if (!currentQuestion.validation(response, responses)) {
			// Handle validation error
			setErrors({
				...errors,
				[`question${questionIndex + 1}`]: "Invalid response. Please check your input."
			});
			return;
		}
	
		// If valid, clear error and move to next question
		setErrors({
			...errors,
			[`question${questionIndex + 1}`]: ''
		});

		// Find the next question that meets the condition or doesn't have a condition
		let nextIndex = questionIndex + 1;
		while (nextIndex < questions.length && questions[nextIndex].condition && !questions[nextIndex].condition(responses)) {
			nextIndex++;
		}
	
		if (nextIndex < questions.length) {
			setQuestionIndex(nextIndex);
		} else {
			// All valid questions answered, handle submission here
			console.log('Responses:', responses);
			setShowModal(false);  // Close the modal when done
			setShowRedirectModal(true);
		}
	};

	// Update the response for the current question
	const handleInputChange = (e) => {
		const currentQuestionKey = `question${questionIndex + 1}`;
		setResponses({
		...responses,
		[currentQuestionKey]: e.target.value,
		});
	};

	const navToSimulationCalculator = () => {
		navigate('/calc');
	};


    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 576);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Structured data for SEO purposes
    const webpageData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Retire Smart Calc",
        "logo": "https://www.retiresmartcalc.com/logo.png",
        "description": "Retirement plan simulation calculator for UK and US investors.",
    };

    return (
        <>
            <Helmet>
                <title>Retire Smart Calc - Retirement plan simulation calculator for UK and US investors</title>
                <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
                <link rel="canonical" href="https://www.retiresmartcalc.com" />
                <meta name="description" content="Retirement plan simulation calculator for UK and US investors." />
                <script type="application/ld+json">
                    {JSON.stringify(webpageData)}
                </script>
            </Helmet>


				<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
					<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
						<Col xs={0} sm={1} />
						<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
						{/* <Col sm={10} className="rounded p-3 mt-2 mb-2"> */}
							<Row className="d-flex h-100 align-items-end">
								<Col xs={0} sm={1} />
								<Col xs={12} xl={4} className="d-flex justify-content-center align-items-end">
									<img src={LogoBanner} alt="Logo" style={{ width: '500px', height: 'auto', maxWidth: '100%', marginBottom: isSmallScreen ? '24px' : '6px' }} />
								</Col>
								<Col xs={12} xl={6} className="d-flex justify-content-center align-items-end">
									<ul style={{ marginBottom: 0, paddingBottom: 0, fontSize: '1.25rem' }}>
										<li>Build a finanical plan</li>
										<li>Back-test it through history</li>
										<li>Optimise plan risk</li>
									</ul>
								</Col>
								<Col xs={0} sm={1} />
							</Row>
							<Row className="justify-content-center mt-5">
								<Col sm="auto">
									<button className={isSmallScreen? 'btn btn-primary'  : 'btn btn-primary btn-lg'} style={{ fontSize: isSmallScreen ? '' : '1.5rem', padding: isSmallScreen ? '' : '12px 24px', marginRight: '6px', marginBottom: '6px' }} onClick={handleShow}>
										Start a Plan
									</button>
									<button className={isSmallScreen? 'btn btn-primary' : 'btn btn-primary btn-lg'} style={{ fontSize: isSmallScreen ? '' : '1.5rem', padding: isSmallScreen ? '' : '12px 24px', marginBottom: '6px' }} onClick={navToSimulationCalculator}>
										Go to Simulation Calculator
									</button>
								</Col>
							</Row>

							<Row className="mt-3" style={{ paddingTop: '16px' }}>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Retire Smart Calc is for investors looking to hold information about their assets in one place and to build, test and optimise a financial plan around those assets. It is free to use and it uniquely offers a choice of simulation datasets for both US or UK based investors.</p>
							</Row>

							<hr></hr>
							<Row className="mt-3" style={{ paddingTop: '16px' }}>
								<h5 className="mb-3">Build your plan:</h5>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Retire Smart Calc is designed specifically to help with retirement finanical planning but you can use it to help plan for other goals too. Use either 'Start a Plan' to be guided through the key inputs or go straight to the Simulation Calculator to get started.</p>
								<Col xs={12} sm={6} md={3}>
								<FaUser size="2.5em" />
								<h6 className="mt-2">Profile</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Plan from current age or a retirement age in the future.</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaBriefcase size="2.5em" />
								<h6 className="mt-2">Portfolio</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Current assets designated for retirement + state pension / social security and (defined benefit) occupational pension income.</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaDollarSign size="2.5em" />
								<h6 className="mt-2">Contributions</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Contributions planned to be made before retirement + any expected one-off inflows (e.g. property downsizing, inheritance, etc).</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaClock size="2.5em" />
								<h6 className="mt-2">Timing</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Planned age of retirement. Changes to contributions or expenditure at different life stages.</p>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs={12} sm={6} md={3}>
								<FaChartPie size="2.5em" />
								<h6 className="mt-2">Asset mix</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Risk-return balance between equities and bonds. Consider index linked as well as conventional bonds.</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaGifts size="2.5em" />
								<h6 className="mt-2">Spending</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Different withdrawal approaches provide fixed certainty or flexibility depending on portfolio performance.</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaBirthdayCake size="2.5em" />
								<h6 className="mt-2">Annuity</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Option to simulate purchasing an annuity at different points in the plan to provide a lifetime income.</p>
								</Col>
								<Col xs={12} sm={6} md={3}>
								<FaMoneyCheckAlt size="2.5em" />
								<h6 className="mt-2">Taxes</h6>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>Taxes that will be deducted from returns for different fund types. Simulation shows results net of tax.</p>
								</Col>
							</Row>
							<hr></hr>
							<Row className="mt-3" style={{ paddingTop: '10px' }}>
								<h5 className="mb-3">Test your plan:</h5>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>The simulation calculator back-tests the plan using actual sequences of historical market returns and inflation data (as far back as 1870 and up until present day). This avoids the need to input asset return assumptions. Each back-test cycle starts from a different historic year in the data-set. The simulation calculator graphically displays the range of outcomes and shows the success rate - the percentage of back-test cycles where the portfolio lasted up until the end of the simulation. Choose whether to use US or Global historic equity market performance data for more robust testing.</p>
							</Row>
							<div className="text-center">
							<Image
								src={ScreenShot} 
								alt="Screenshot"
								className="rounded-border-image"
								rounded // This applies the rounded corners
								fluid  // Makes the image responsive
								style={{ width: '60%', height: 'auto' }}
							/>
							</div>

							<hr></hr>
							<Row className="mt-3" style={{ paddingTop: '10px', paddingBottom: '10px'}}>
								<h5 className="mb-3">Optimise and implement your plan:</h5>
								<p className={isSmallScreen ? 'small' : ''} style={{ color: '#d3d3d3' }}>RetireSmartCalc can be used in a number of ways to help with these kinds of plan optimisation questions. Create a user account and save up to 5 plans to easily compare strategies and consider your options.</p>
								<Row>

								<Col xs={12} lg={3} className="d-flex flex-column align-items-start" style={{ marginBottom: '5px' }}>
								<div className="d-flex align-items-start">
									<FaQuestion className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }} />
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									How much can I spend through retirement / withdrawal from my portfolio each year? Can flexibility in year to year spending increase what I can expect to spend overall?
									</div>
								</div>
								
								<div className="d-flex align-items-start" style={{ marginTop: '8px' }}>
									<FaCheck className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }} />
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									Simulate a variety of fixed and flexible withdrawal strategies including 'fixed' dollar, 'fixed + bonus', Yale, Vanguard and others. Flexible strategies can offer higher 'potential' spending during retirement but impose rules to reduce spending in event of poor market performance. Calculate a safe withdrawal rate that is right for your plan.
									</div>
								</div>
								</Col>

								<Col xs={12} lg={3} className="d-flex flex-column align-items-start" style={{ marginBottom: '5px' }}>
								<div className="d-flex align-items-start">
									<FaQuestion className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }}/>
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									As I progress through retirement, how do I know if and when I can safely spend more?
									</div>
								</div>

								<div className="d-flex align-items-start" style={{ marginTop: '8px' }}>
									<FaCheck className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }} />
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									Retire Smart Calc automatically calculates a <em>minimum safe funding</em> requirement through the plan - that is, the minimum amount required to ensure the success of the plan in all back-tests. It can simulate a 'fixed + bonus' withdrawal strategy that guides a bonus withdrawal when the portfolio value is above the minimum safe funding level. 
									</div>
								</div>
								</Col>	

								<Col xs={12} lg={3} className="d-flex flex-column align-items-start" style={{ marginBottom: '5px' }}>
								<div className="d-flex align-items-start">
									<FaQuestion className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }}/>
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									Should I shift from a high risk-return asset allocation to 'locking-in' returns? Can less plan risk mean I can spend more? 
									</div>
								</div>
									<div className="d-flex align-items-start" style={{ marginTop: '8px' }}>
									<FaCheck className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }}/>
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									Test the impact of your portfolio asset mix on the minimum safe funding level and safe withdrawal rates. Retire Smart Calc gives you the option to test your plan using forward looking market yields rather than historic returns - this enables the consideration of allocating to inflation linked bonds as well as conventional bonds in order to better manage down inflation risk in your plan.
									</div>
								</div>
								</Col>

								<Col xs={12} lg={3} className="d-flex flex-column align-items-start" style={{ marginBottom: '5px' }}>
								<div className="d-flex align-items-start">
									<FaQuestion className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }}/>
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									If I want to 'lock-in' more of my plan, how should I do it?
									</div>
								</div>
								<div className="d-flex align-items-start" style={{ marginTop: '8px' }}>
									<FaCheck className="fixed-size flex-shrink-0" style={{ width: '24px', height: '24px' }}/>
									<div className={isSmallScreen ? 'small ms-2' : 'ms-2'} style={{ color: '#d3d3d3' }}>
									Compare growth vs. balanced vs. inflation linked bond portfolio vs. annuitisation asset mix strategies. Look at the impact on safe spending plans, tax efficiency, the risk of running out of money and potential legacy portfolio. Retire Smart Calc's bond ladder tool helps construct a inflation linked bond portfolio that matches expenditure plans.
									</div>
								</div>
								</Col>			
								</Row>
							</Row>

							<hr></hr>

							<Row>
							<Col>
							<p className='small' style={{ color: '#d3d3d3' }}>Disclaimer: RetireSmartCalc including its simulation tools, content, data, etc do not constitute investment advice. RetireSmartCalc is designed as an educational aid for experienced investors who are familiar with financial and investing concepts and who understand that future investment returns may be different from historic returns. Seek advice from a qualified financial advisor or planner if you are unsure. See <Link to="/terms" className="link-light">terms of service.</Link></p>
							</Col>
							</Row>
							<Row>
							<Col className="d-flex align-items-start">
							<FaEnvelope size="2em" />
							<span className="small ms-2" style={{ color: '#d3d3d3' }}>RetireSmartCalc is in beta release. Please get in touch with any questions, feedback or ideas: contact@retiresmartcalc.com</span>
							</Col>
							</Row>
						</Col>
						<Col xs={0} sm={1} />					
					</Row>

				{/* Modal Component */}
				<Modal show={showRedirectModal} onHide={handleRedirectClose}>
					<Modal.Header closeButton>
					<Modal.Title>Start a Plan</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Thank you for your responses. You will now be taken to the Calculation Simulator where your responses have been used to create a new plan. Press the Run Simulation button to test the plan.</p>
						<p>You will then be able to edit and enhance your plan and re-test as many times as you want. If you want to save your plan, then create a user account.</p>
						<h6>Note:</h6>
						<ul>
							<li>End of plan has been set to 100 years old.</li>
							<li>Tax deductions have been set at 0% on all types of pension income / withdrawals.</li>
							<li>Portfolio management fee deduction is set at 0bp.</li>
						</ul>
						<p>You can change all these and other assumptions in the simulation calculator to fit your specific circumstances.</p>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="primary" onClick={handleRedirectClose}>
						Continue
					</Button>
					</Modal.Footer>
				</Modal>

				{/* Modal Component */}
				<Modal show={showModal} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
					<Modal.Title>Start A Plan</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<Form>
            {/* Conditionally render text input or radio buttons based on question type */}
            <Form.Group>
              <Form.Label>{questions[questionIndex].question}</Form.Label>
              {questions[questionIndex].type === "text" || questions[questionIndex].type === "number" ? (
                <Form.Control
                  type={questions[questionIndex].type}
                //   value={responses[`question${questionIndex + 1}`]}
				  value={responses[`question${questionIndex + 1}`] !== null ? responses[`question${questionIndex + 1}`] : ''}
                  onChange={handleInputChange}
                  isInvalid={!!errors[`question${questionIndex + 1}`]}
                />
              ) : (
                <div>
                  {questions[questionIndex].options.map((option, index) => (
                    <Form.Check 
                      key={index}
                      type="radio" 
                      label={option} 
                      name="yesNo" 
                      value={option} 
                      onChange={handleInputChange}
                      checked={responses[`question${questionIndex + 1}`] === option}
                    />
                  ))}
                </div>
              )}
              <Form.Control.Feedback type="invalid">
                {errors[`question${questionIndex + 1}`]}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

					</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleNext}>
						{questionIndex < questions.length - 1 ? 'Next' : 'Submit'}
					</Button>
					</Modal.Footer>
				</Modal>

				</Container>

        </>
    );
}

export default Intro;



