import React, { useEffect, useState, Component } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
  
function FormTemplateInteger(props) {

  return (
    <div>
      <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>{props.overlaytext}</Tooltip>}>
        <Form.Label htmlFor={props.formname} className="fw-light small">{props.formlabel}</Form.Label>
      </OverlayTrigger>
      <Form.Control
        type={props.type}
        name={props.formname}
        id={props.formname}
        value={props.presetObject[props.formname]} // Dynamically access the value
        onChange={(e) => { if (/^\d*$/.test(e.target.value)) {props.handleObjectChange(props.formname, e.target.value);}}}
        className="form-control bg-secondary border-secondary text-light"
        autoComplete="off"
      >
      </Form.Control>
      {props.errors[props.formname] && <Alert className="alert alert-danger">{props.errors[props.formname]}</Alert>}
    </div>
  );
}

export default FormTemplateInteger;


{/* <Form.Control
  type={props.type}
  name={props.formname}
  id={props.formname}
  value={props.presetObject[props.formname]} // Dynamically access the value
  onChange={(e) => {
    const value = e.target.value;
    // Use a regular expression to ensure only digits are entered (no decimals)
    if (/^\d*$/.test(value)) {
      props.handleObjectChange(props.formname, value); // Update only if it's an integer
    }
  }}
  className="form-control bg-secondary border-secondary text-light"
  autoComplete="off"
/> */}