import { useAuthStore } from '../store/auth';
import axios from './axios';
import { setAuthUser, setAuthUserToStore } from '../utils/auth'

function useLogin() {
    const { setCSRFToken, setAccessToken, setRefreshToken } = useAuthStore();

    const login = async (username, password) => {
        try {
            const { data, status, headers } = await axios.post('login/', {
                username,
                password,
            });
            if (status === 200) {
                setAuthUserToStore(data.access);
                setAccessToken(data.access);
            }
            return { data, error: null };
        } catch (error) {
            const responseData = error.response?.data || {};
            const usernameError = responseData.username || null;
            const passwordError = responseData.password || null;
            return {
                data: null,
                error: {
                    username: usernameError,
                    password: passwordError,
                    detail: responseData.detail || 'Something went wrong',
                },
            };
        }
    };

    return { login }; 
}

export default useLogin;