import React, { useEffect, useState, useRef, Component } from 'react'
import useAxios from '../utils/useAxios';
import ValidateForm from "../components/validation.js"
import ValidateIncomeForm from "../components/validation_income_ladder.js"
import validatesanserrorsForm from "../components/validationsanserrors.js"
import PlanButtons from "../components/planbuttons.js";
import ReLoginForm from '../views/relogin';
import { usePresetStore } from "../components/datastore.js";
import { useAuthStore } from '../store/auth'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal, ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import '../styles/mystyles.css'
import '../App.css' // Import the CSS file
import { FaInfoCircle, FaDownload } from 'react-icons/fa'; 
import { useLocation } from 'react-router-dom';

function Ladder() {
    const tempvar = false

    const api_key = 'seh3599f@fUERkdf22&£^2{u'

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    useEffect(() => {
      const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
      };
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
    }, []);

    const webpageData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Retire Smart Calc",
      "logo": "https://www.retiresmartcalc.com/logo.png",
      "description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
    };
    

    const api = useAxios();  // Ensure this is at the top level of your component or function
    const location = useLocation();

    // const todayfull = new Date().toISOString().split('T')[0];
    // const today = new Date(todayfull.getFullYear(), todayfull.getMonth(), 1);

    // const todayfull = new Date();
    // const todaydate = new Date(todayfull.getFullYear(), todayfull.getMonth(), 1);
    // const today =  todaydate .toISOString().split('T')[0];

    const todayraw = new Date();
    const today = todayraw.toISOString().split('T')[0];
    const month = new Date(todayraw.getFullYear(), todayraw.getMonth(), 1).toISOString().split('T')[0];

    const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
    const planId = usePresetStore((state) => state.planId);
    const setPlanId = usePresetStore((state) => state.setPlanId);
    const resetPlanId = usePresetStore((state) => state.resetPlanId);
    const logInState = useAuthStore().isLoggedIn();
    const userId = useAuthStore().user();

    const [yearList, setYearList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [optrun, setOptrun] = useState(false);
    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Calculating...')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true)};
    const closeModal = () => {setIsModalOpen(false)};

    const [isReLoginModalOpen, setIsReLoginModalOpen] = useState(false);
    const openReLoginModal = () => setIsReLoginModalOpen(true);
    const closeReLoginModal = () => setIsReLoginModalOpen(false);

    const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
    const openIncomeModal = () => {setIsIncomeModalOpen(true)};
    const closeIncomeModal = () => {setIsIncomeModalOpen(false)};  

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [modalResetMessage, setModalResetMessage] = useState('Are you sure you want to reset the data? This action cannot be undone.');
    const openResetModal = () => {setIsResetModalOpen(true)};
    const closeResetModal = () => {setIsResetModalOpen(false)};
    
    const [modalmessage, setModalmessage] = useState();

    const [plans, setPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(null); // State for selected plan ID for deletion

    const [isRetrieveModalOpen, setIsRetrieveModalOpen] = useState(false);
    const openRetrieveModal = () => {setIsRetrieveModalOpen(true)};
    const closeRetrieveModal = () => {setIsRetrieveModalOpen(false)};

    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal visibility

    const [isAddIncomeRequirementsModalOpen, setIsAddIncomeRequirementsModalOpen] = useState(false);
    const openAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(true);
    const closeAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(false);

    const [isDataChoiceModalOpen, setIsDataChoiceModalOpen] = useState(false);
    const openDataChoiceModal = () => setIsDataChoiceModalOpen(true);
    const closeDataChoiceModal = () => setIsDataChoiceModalOpen(false);

    const [isBondMatchingModalOpen, setIsBondMatchingModalOpen] = useState(false);
    const openBondMatchingModal = () => setIsBondMatchingModalOpen(true);
    const closeBondMatchingModal = () => setIsBondMatchingModalOpen(false);

    const [isBondChoiceModalOpen, setIsBondChoiceModalOpen] = useState(false);
    const openBondChoiceModal = () => setIsBondChoiceModalOpen(true);
    const closeBondChoiceModal = () => {
      setPresetObject({bond_ladder: bondList})
      setIsBondChoiceModalOpen(false)};

    const [currencyIcon, setCurrencyIcon] = useState('');

    useEffect(() => {
      setCurrencyIcon(presetObject.currency_set === 'GBP' ? '\u00A3' : '$');
    }, [presetObject.currency_set]);

    useEffect(() => {
      setYearList(Array.from({ length: presetObject.data_end_year - presetObject.data_start_year + 1}, (_, index) => presetObject.data_start_year + index));
    }, [presetObject.data_option]);

    useEffect(() => {
      getSimulationData();
      setPresetObject({bond_ladder: bondList})
    }, []);

    const [showDateModal, setShowDateModal] = useState(false)

    const [ladderOption, setLadderOption] = useState('0')

    const [bondList, setBondList] = useState([]);

    useEffect(() => {
      let bond_array;
      if (presetObject.currency_set === 'GBP') { 
      bond_array = [
          { name: '0 1/8% Index-linked Gilt Mar 2026', coupon: 0.125, maturity: '2026-03-22', include: true  },
          { name: '1 1/4% Index-linked Gilt Nov 2027', coupon: 1.250, maturity: '2027-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Aug 2028', coupon: 0.125, maturity: '2028-08-10', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2029', coupon: 0.125, maturity: '2029-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Aug 2031', coupon: 0.125, maturity: '2031-08-10', include: true  },
          { name: '1 1/4% Index-linked Gilt Nov 2032', coupon: 1.125, maturity: '2032-11-22', include: true  },
          { name: '0 3/4% Index-linked Gilt Nov 2033', coupon: 0.750, maturity: '2033-11-22', include: true  },
          { name: '0 3/4% Index-linked Gilt Mar 2034', coupon: 0.750, maturity: '2034-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Nov 2036', coupon: 0.125, maturity: '2036-11-22', include: true  },
          { name: '1 1/8% Index-linked Gilt Nov 2037', coupon: 1.125, maturity: '2037-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2039', coupon: 0.125, maturity: '2039-03-22', include: true  },
          { name: '0 5/8% Index-linked Gilt Mar 2040', coupon: 0.625, maturity: '2040-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Aug 2041', coupon: 0.125, maturity: '2041-08-10', include: true  },
          { name: '0 5/8% Index-linked Gilt Nov 2042', coupon: 0.625, maturity: '2042-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2044', coupon: 0.125, maturity: '2044-03-22', include: true  },
          { name: '0 5/8% Index-linked Gilt Mar 2045', coupon: 0.625, maturity: '2045-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2046', coupon: 0.125, maturity: '2046-03-22', include: true  },
          { name: '0 3/4% Index-linked Gilt Nov 2047', coupon: 0.750, maturity: '2047-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Aug 2048', coupon: 0.125, maturity: '2048-08-10', include: true  },
          { name: '0 1/2% Index-linked Gilt Mar 2050', coupon: 0.500, maturity: '2050-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2051', coupon: 0.125, maturity: '2051-03-22', include: true  },
          { name: '0 1/4% Index-linked Gilt Mar 2052', coupon: 0.250, maturity: '2052-03-22', include: true  },
          { name: '1 1/4% Index-linked Gilt Nov 2054', coupon: 1.250, maturity: '2054-11-22', include: true  },
          { name: '1 1/4% Index-linked Gilt Nov 2055', coupon: 1.250, maturity: '2055-11-22', include: true  },
          { name: '0 1/8% Index-Linked Gilt Nov 2056', coupon: 0.125, maturity: '2056-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2058', coupon: 0.125, maturity: '2058-03-22', include: true  },
          { name: '0 3/8% Index-linked Gilt Mar 2062', coupon: 0.375, maturity: '2062-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Nov 2065', coupon: 0.125, maturity: '2065-11-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2068', coupon: 0.125, maturity: '2068-03-22', include: true  },
          { name: '0 1/8% Index-linked Gilt Mar 2073', coupon: 0.125, maturity: '2073-03-22', include: true  },
      ]
       } else {
      bond_array = [
          { name: 'US TIPS 2025-01-15 0.250%', coupon: 0.250, maturity: '2025-01-15', include: true },
          { name: 'US TIPS 2025-04-15 0.125%', coupon: 0.125, maturity: '2025-04-15', include: true },
          { name: 'US TIPS 2025-07-15 0.375%', coupon: 0.375, maturity: '2025-07-15', include: true },
          { name: 'US TIPS 2025-10-15 0.125%', coupon: 0.125, maturity: '2025-10-15', include: true },
          { name: 'US TIPS 2026-01-15 0.625%', coupon: 0.625, maturity: '2026-01-15', include: true },
          { name: 'US TIPS 2026-04-15 0.125%', coupon: 0.125, maturity: '2026-04-15', include: true },
          { name: 'US TIPS 2026-07-15 0.125%', coupon: 0.125, maturity: '2026-07-15', include: true },
          { name: 'US TIPS 2026-10-15 0.125%', coupon: 0.125, maturity: '2026-10-15', include: true },
          { name: 'US TIPS 2027-01-15 0.375%', coupon: 0.375, maturity: '2027-01-15', include: true },
          { name: 'US TIPS 2027-04-15 0.125%', coupon: 0.125, maturity: '2027-04-15', include: true },
          { name: 'US TIPS 2027-07-15 0.375%', coupon: 0.375, maturity: '2027-07-15', include: true },
          { name: 'US TIPS 2027-10-15 1.625%', coupon: 1.625, maturity: '2027-10-15', include: true },
          { name: 'US TIPS 2028-01-15 0.500%', coupon: 0.500, maturity: '2028-01-15', include: true },
          { name: 'US TIPS 2028-04-15 1.250%', coupon: 1.250, maturity: '2028-04-15', include: true },
          { name: 'US TIPS 2028-07-15 0.750%', coupon: 0.750, maturity: '2028-07-15', include: true },
          { name: 'US TIPS 2028-10-15 2.375%', coupon: 2.375, maturity: '2028-10-15', include: true },
          { name: 'US TIPS 2029-01-15 0.875%', coupon: 0.875, maturity: '2029-01-15', include: true },
          { name: 'US TIPS 2029-04-15 2.125%', coupon: 2.125, maturity: '2029-04-15', include: true },
          { name: 'US TIPS 2029-07-15 0.250%', coupon: 0.250, maturity: '2029-07-15', include: true },
          { name: 'US TIPS 2030-01-15 0.125%', coupon: 0.125, maturity: '2030-01-15', include: true },
          { name: 'US TIPS 2030-07-15 0.125%', coupon: 0.125, maturity: '2030-07-15', include: true },
          { name: 'US TIPS 2031-01-15 0.125%', coupon: 0.125, maturity: '2031-01-15', include: true },
          { name: 'US TIPS 2031-07-15 0.125%', coupon: 0.125, maturity: '2031-07-15', include: true },
          { name: 'US TIPS 2032-01-15 0.125%', coupon: 0.125, maturity: '2032-01-15', include: true },
          { name: 'US TIPS 2032-04-15 3.375%', coupon: 3.375, maturity: '2032-04-15', include: true },
          { name: 'US TIPS 2032-07-15 0.625%', coupon: 0.625, maturity: '2032-07-15', include: true },
          { name: 'US TIPS 2033-01-15 1.125%', coupon: 1.125, maturity: '2033-01-15', include: true },
          { name: 'US TIPS 2033-07-15 1.375%', coupon: 1.375, maturity: '2033-07-15', include: true },
          { name: 'US TIPS 2034-01-15 1.750%', coupon: 1.750, maturity: '2034-01-15', include: true },
          { name: 'US TIPS 2034-07-15 1.875%', coupon: 1.875, maturity: '2034-07-15', include: true },
          { name: 'US TIPS 2040-02-15 2.125%', coupon: 2.125, maturity: '2040-02-15', include: true },
          { name: 'US TIPS 2041-02-15 2.125%', coupon: 2.125, maturity: '2041-02-15', include: true },
          { name: 'US TIPS 2042-02-15 0.750%', coupon: 0.750, maturity: '2042-02-15', include: true },
          { name: 'US TIPS 2043-02-15 0.625%', coupon: 0.625, maturity: '2043-02-15', include: true },
          { name: 'US TIPS 2044-02-15 1.375%', coupon: 1.375, maturity: '2044-02-15', include: true },
          { name: 'US TIPS 2045-02-15 0.750%', coupon: 0.750, maturity: '2045-02-15', include: true },
          { name: 'US TIPS 2046-02-15 1.000%', coupon: 1.000, maturity: '2046-02-15', include: true },
          { name: 'US TIPS 2047-02-15 0.875%', coupon: 0.875, maturity: '2047-02-15', include: true },
          { name: 'US TIPS 2048-02-15 1.000%', coupon: 1.000, maturity: '2048-02-15', include: true },
          { name: 'US TIPS 2049-02-15 1.000%', coupon: 1.000, maturity: '2049-02-15', include: true },
          { name: 'US TIPS 2050-02-15 0.250%', coupon: 0.250, maturity: '2050-02-15', include: true },
          { name: 'US TIPS 2051-02-15 0.125%', coupon: 0.125, maturity: '2051-02-15', include: true },
          { name: 'US TIPS 2052-02-15 0.125%', coupon: 0.125, maturity: '2052-02-15', include: true },
          { name: 'US TIPS 2053-02-15 1.500%', coupon: 1.500, maturity: '2053-02-15', include: true },
          { name: 'US TIPS 2054-02-15 2.125%', coupon: 2.125, maturity: '2054-02-15', include: true }
        ]};
        setBondList(bond_array)
        setPresetObject({bond_ladder : bond_array})
    }, [presetObject.currency_set])

    const handleBondInclude = (index) => {
      const updatedBondList = [...bondList];
      updatedBondList[index].include = !updatedBondList[index].include;
      setBondList(updatedBondList);
    };

    const getPlans = async () => {
      try {
          const response = await api.get('/getplans/');
          const data = response.data;
          setPlans(data.plans);
          openRetrieveModal();
      } catch (error) {
          // ideally would have a mechanism here to identify type 400 or 401 errors and auto relogin with openReLoginModal() but because the error is triggered inside axiom interceptor, the required error.response.status isn't passed to this point
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
        }
   };

    const handleOpenDeleteModal = (planIdNumber) => {
      setSelectedPlanId(planIdNumber); // Set the planId to delete
      setShowDeleteModal(true); // Show the confirmation modal
    };
  
    const handleCloseDeleteModal = () => {
      setSelectedPlanId(null); // Clear selected plan ID
      setShowDeleteModal(false); // Close the confirmation modal
    };

    const deletePlan = async (planIdNumber) => {
      try {
        // Send DELETE request as a POST request with planIdNumber in the body
        const response = await api.post('/deleteplan/', { plan_id: planIdNumber });
        // Update plans by removing the deleted plan
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planIdNumber));
        // Reset the state if the deleted plan was the currently active one
        if (planIdNumber === planId) {
          resetPlanId();
          resetPresetObject();
        }
      } catch (error) {     
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
      } 
      handleCloseDeleteModal();
    };

    const retrievePlan = async (planIdNumber) => {
        try {
          const response = await api.post('/retrieve/', { plan_id: planIdNumber });
          const data = response.data;
          console.log(data)
          if (response.status === 200) {  
            {setPresetObject({ 
              ['plan_title']: data.plan_title,
              ['plan_date']: data.plan_date,
              ['data_start_year']: data.data_start_year,
              ['data_end_year']: data.data_end_year,
              ['currency_set']: data.currency_set,
              ['geographic_set']: data.geographic_set,
              ['equity_tax']: data.equity_tax,
              ['bond_tax']: data.bond_tax,
              ['draw_tax']: data.draw_tax,
              ['bond_coupon']: data.bond_coupon,
              ['index_bond_coupon']: data.index_bond_coupon,
              ['asset_mix_equity']: data.asset_mix_equity,
              ['asset_mix_bond']: data.asset_mix_bond,
              ['asset_mix_index_bond']: data.asset_mix_index_bond,
              ['asset_mix_source']: data.asset_mix_source,
              ['data_option']: data.data_option,
              ['data_direction']: data.data_direction,
              ['dynamic_option']: data.dynamic_option,
              ['withdrawal_percent']: data.withdrawal_percent,
              ['target_withdrawal_percent']: data.target_withdrawal_percent,
              ['net_other_income']: data.net_other_income,
              ['yale_weighting']: data.yale_weighting,
              ['vanguard_decrease_floor']: data.vanguard_decrease_floor,
              ['vanguard_increase_ceiling']: data.vanguard_increase_ceiling,
              ['annuity_tax_rate']: data.annuity_tax_rate,
              ['annuity_tax_rate2']: data.annuity_tax_rate2,
              ['annuity_tax_rate3']: data.annuity_tax_rate3,
              ['fees']: data.fees,
              ['spread']: data.spread,
              ['equity_alpha']: data.equity_alpha,
              ['fixed_income_alpha']: data.fixed_income_alpha,
              ['start_simulation_age']: data.start_simulation_age,
              ['end_simulation_age']: data.end_simulation_age,
              ['circular_simulation']: data.circular_simulation,
              ['extraordinary_inflows']: data.extraordinary_inflows,
              ['state_pension']: data.state_pension,
              ['occupational_pension']: data.occupational_pension,
              ['annuity_pension']: data.annuity_pension,
              ['contribution_inflows']: data.contribution_inflows,
              ['income_requirements']: data.income_requirements,
              ['ladder_income_requirements']: data.ladder_income_requirements,
              ['flex_income_requirements']: data.flex_income_requirements,
              ['asset_portfolio']: data.asset_portfolio,
              ['asset_deduction']: data.asset_deduction,
              ['apply_tax_to_inflation']: data.apply_tax_to_inflation,
            })};
            setPlanId(planIdNumber);
            closeRetrieveModal();
            // setModalmessage('Portfolio data retrieved.');
            // openModal()
          };
        } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If still not working, try logging out and logging in again.');
          openModal();
      };
    };

        const checkNumberPlans = async () => {
          try {
            const response = await api.get('/getplans/'); // Fetch the list of plans
            const data = response.data;
            const response2 = await api.get('/userinformation/');  // Fetch the user's permitted number of plans
            const permittedNumberOfPlans = response2.data
            const numberOfPlans = data.plans.length;
            if (numberOfPlans < permittedNumberOfPlans.userinformation.number_plans) {return true} else {return false}
          } catch (error) {
            // console.error('Error fetching plans:', error);
            return 0; // In case of an error, return 0 (or handle this case as you see fit)
          }
        };

        const savePlan = () => {
          if (presetObject.plan_date !== today) {setShowDateModal(true)} else {handleDateModalClose(false)}
          // setShowDateModal(true);
        };

        const handleDateModalClose = async (choice) => {
          setShowDateModal(false);
          console.log(presetObject);

          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              let requestData;
              if (choice === true) {
                setPresetObject({['plan_date']: today})
                requestData = {
                  ...presetObject,
                  ...(planId && { planId }),  // Include planId if it's not null or undefined
                  plan_date: today  // Add this only if needed
                };
              } else {
                requestData = {
                  ...presetObject,
                  ...(planId && { planId })  // Include planId if it's not null or undefined
                };
              }
              const response = await api.post('/save/', requestData);
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved.');
                setPlanId(response.data.plan_id)
                openModal();
              }
            }
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

        const copyPlan = async () => {
          console.log(presetObject);
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              const requestData = {
                ...presetObject,
                // ...(planId && { planId })  // Include planId if it's not null or undefined
              };
              const response = await api.post('/save/', requestData);
        
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved as a new plan.');
                setPlanId(response.data.plan_id)
                openModal();
              }}
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

    const resetPlan = () => {
      resetPresetObject();
      resetPlanId();
      closeResetModal();
    };

    // incomeYear and incomeYearEnd given placeholder values to stop backend rejection of null field

    const [incomeName, setIncomeName] = useState('');
    const [incomeYear, setIncomeYear] = useState(65);
    const [incomeYearEnd, setIncomeYearEnd] = useState(95);
    const [incomeAmount, setIncomeAmount] = useState('');
    const [incomeIndex, setIncomeIndex] = useState('1');
    const [incomeIncrease, setIncomeIncrease] = useState('0');
    const [incomeIncrease2, setIncomeIncrease2] = useState('0');
    const [incomeDate, setIncomeDate] = useState(month);
    const [incomeSingleYear, setIncomeSingleYear] = useState('0');
    const [incomePercentCovered, setIncomePercentCovered] = useState(100);
    const [incomeJanuary, setIncomeJanuary] = useState('1');
    const [incomeFebruary, setIncomeFebruary] = useState('1');
    const [incomeMarch, setIncomeMarch] = useState('1');
    const [incomeApril, setIncomeApril] = useState('1');
    const [incomeMay, setIncomeMay] = useState('1');
    const [incomeJune, setIncomeJune] = useState('1');
    const [incomeJuly, setIncomeJuly] = useState('1');
    const [incomeAugust, setIncomeAugust] = useState('1');
    const [incomeSeptember, setIncomeSeptember] = useState('1');
    const [incomeOctober, setIncomeOctober] = useState('1');
    const [incomeNovember, setIncomeNovember] = useState('1');
    const [incomeDecember, setIncomeDecember] = useState('1');
    const [incomeStartDate, setIncomeStartDate] = useState(month);
    const [incomeEndDate, setIncomeEndDate] = useState(month);

    const handleIncomeChange = () => {
        if (ValidateIncomeForm(presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, incomeDate, incomePercentCovered, incomeStartDate, incomeEndDate, setErrors, setIsModalOpen, setModalmessage)) { 
        if (incomeIndex === '0' || incomeIndex === '1') {setPresetObject({ladder_income_requirements: [...presetObject.ladder_income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease, last_update_date: incomeDate, single_year: incomeSingleYear, percent_covered: incomePercentCovered, january: incomeJanuary, february: incomeFebruary, march: incomeMarch, april: incomeApril, may: incomeMay, june: incomeJune, july: incomeJuly, august: incomeAugust, september: incomeSeptember, october: incomeOctober, november: incomeNovember, december: incomeDecember, date_start: incomeStartDate, date_end: incomeEndDate}]})} else {setPresetObject({...presetObject, ladder_income_requirements: [...presetObject.ladder_income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease2, last_update_date: incomeDate, single_year: incomeSingleYear, percent_covered: incomePercentCovered, january: incomeJanuary, february: incomeFebruary, march: incomeMarch, april: incomeApril, may: incomeMay, june: incomeJune, july: incomeJuly, august: incomeAugust, september: incomeSeptember, october: incomeOctober, november: incomeNovember, december: incomeDecember, date_start: incomeStartDate, date_end: incomeEndDate}]})};
        console.log(incomeJanuary, incomeFebruary, incomeMarch, incomeApril, incomeMay, incomeJune, incomeJuly, incomeAugust, incomeSeptember, incomeOctober, incomeNovember, incomeDecember)
        setIncomeName('');
        setIncomeYear(65);
        setIncomeYearEnd(95);
        setIncomeAmount('');
        setIncomeIndex('1');
        setIncomeIncrease(0);
        setIncomeIncrease2(0);
        setIncomeDate(month);
        setIncomeSingleYear('0')
        setIncomePercentCovered(100);
        setIncomeJanuary('1');
        setIncomeFebruary('1');
        setIncomeMarch('1');
        setIncomeApril('1');
        setIncomeMay('1');
        setIncomeJune('1');
        setIncomeJuly('1');
        setIncomeAugust('1');
        setIncomeSeptember('1');
        setIncomeOctober('1');
        setIncomeNovember('1');
        setIncomeDecember('1');
        setIncomeStartDate(month);
        setIncomeEndDate(month);  
        closeIncomeModal();
      };
    }; 

    const handleIncomeDeleteItem = (index) => {
      setPresetObject({
        ladder_income_requirements: presetObject.ladder_income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleIncomeModityItem = (index) => {
      const item = presetObject.ladder_income_requirements[index];
      setIncomeName(item.name);
      setIncomeYear(item.year); 
      setIncomeYearEnd(item.yearend);
      setIncomeAmount(item.amount);
      setIncomeIndex(item.index);
      setIncomeIncrease(item.increase);
      setIncomeIncrease2(item.increase2);
      setIncomeDate(item.last_update_date);
      setIncomeSingleYear(item.single_year);
      setIncomePercentCovered(item.percent_covered);
      setIncomeJanuary(item.january);
      setIncomeFebruary(item.february);
      setIncomeMarch(item.march);
      setIncomeApril(item.april);
      setIncomeMay(item.may);
      setIncomeJune(item.june);
      setIncomeJuly(item.july);
      setIncomeAugust(item.august);
      setIncomeSeptember(item.september);
      setIncomeOctober(item.october);
      setIncomeNovember(item.november);
      setIncomeDecember(item.december);
      setIncomeStartDate(item.date_start);
      setIncomeEndDate(item.date_end);

      setPresetObject({
        ladder_income_requirements: presetObject.ladder_income_requirements.filter((_, i) => i !== index),
      });
      openIncomeModal();
    };

    const handleObjectChange = (property, value) => {
      // setPresetObject({[property]: parseFloat(value),});
      if (validatesanserrorsForm({...presetObject,[property]: parseFloat(value),})) {
        setPresetObject({[property]: parseFloat(value),});
      };
    };

    const handleDateChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleTextChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: value,})) {
        setPresetObject({[property]: value,});
      };
    };

    const handleSelectChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const [cashNeeded, setCashNeeded] = useState(null)
    const [bondDiscountCost, setBondDiscountCost] = useState(null)
    const [bondLadder, setBondLadder] = useState([])

    // const runAnalysis = async () => {
    //   // console.log(presetObject);
    //   try {
    //     const response = await api.post('/bondladder/', presetObject);
    //   setCashNeeded(response.data.cash);
    //   setBondDiscountCost(response.data.bond_cost);
    //   setBondLadder(response.data.ladder);
    //   // console.log(response.data);
    //   } catch (error) {console.log(error)}
    // }

    const runAnalysis = async () => {
      let apiroute = ""
      apiroute = "bondladder"
      try {
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key, // Set the custom header here
            },
          body: JSON.stringify(presetObject),});
        const data = await response.json();
      setCashNeeded(data.cash);
      setBondDiscountCost(data.bond_cost);
      setBondLadder(data.ladder);
      // console.log(response.data);
      } catch (error) {console.log(error)}
    }

    const getSimulationData = async () => {
      let apiroute = ""
      apiroute = "getsimulationdata"
      try {
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key // Set the custom header here
            },
        });
        const data = await response.json();
      setPresetObject({['historic_asset_return_data']: data.historic_dataset, ['forward_asset_return_data']: data.forward_dataset, ['mortality_data']: data.mortality_dataset});
      } catch (error) {console.log(error)}
    }

    function convertToCSV(file) {
      const headers = Object.keys(file[0]).join(',');
      const rows = file.map(row => Object.values(row).join(','));
      return [headers, ...rows].join('\n');
    }
    
    function downloadCSV(file) {
      const csvData = convertToCSV(file);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
    
      const link = document.createElement('a');
      link.href = url;
      link.download = 'bondLadder.csv';
      document.body.appendChild(link);
      link.click();
    
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }


    return (

      <div>
        	<Helmet>
            <title>Retire Smart Calc - Retirement Portfolio Simulation Calculator</title>
            <meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
            <link rel="canonical" href="https://www.retiresmartcalc.com/#/calc"></link>
            <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
            <script type="application/ld+json">
            {JSON.stringify(webpageData)}
            </script>
          </Helmet>
      
      <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        <Row style={{ display: 'flex', gap: '0px' }}>
          <Col sm={4} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>

          <div className="d-flex align-items-center">
            <p className="lead mb-0">Ladder options:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">Add in expenditure items.  They are held seperately from the main plan although they are saved to same account.  Expenditure items need to be entered in net (e.g. net of other income that will be used to cover some of the expenditure item).</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>

          <PlanButtons 
          logInState={logInState} 
          userId={userId} 
          getPlans={getPlans} 
          copyPlan={copyPlan}
          savePlan={savePlan} 
          openResetModal={openResetModal} 
          planAlreadySaved={planId}
          />

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} onClick={openAddIncomeRequirementsModal}>
              {presetObject.dynamic_option === 'proportional' || presetObject.dynamic_option === 'yale' || presetObject.dynamic_option === 'vanguard' ? (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add planned expenditure items</span>) : (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add planned expenditure items</span>)}     
                <span className="badge-space"><Badge bg="info">{presetObject.ladder_income_requirements.length ? presetObject.ladder_income_requirements.length : null}</Badge></span>
              </Button>
            </div>

            {/* IncomeRequirements Modal */}
            <Modal show={isAddIncomeRequirementsModalOpen} onHide={closeAddIncomeRequirementsModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Planned Expenditure (For Bond Ladder)</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              
              <div className="mt-4" />

              <Row>
                {presetObject.ladder_income_requirements
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Annual amount ({currencyIcon}): {item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Percent covered (%): {item.percent_covered}</Card.Text>   
                          {/* <Card.Text className="small tight-spacing">{item.single_year === '0' ? 'Multi-year expenditure' : 'Single-year (one-off)'}</Card.Text>
                          {item.single_year === '0' ? 
                          <>
                          <Card.Text className="small tight-spacing">Start age: {item.year}</Card.Text>     
                          <Card.Text className="small tight-spacing">End age: {item.yearend}</Card.Text>
                          </>
                          :
                          <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                          }      */}
                          <Card.Text className="small tight-spacing">Start month: {new Date(item.date_start).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' })}</Card.Text>
                          <Card.Text className="small tight-spacing">End month: {new Date(item.date_end).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' })}</Card.Text>
                          <Card.Text className="small tight-spacing">Months: {item.january === '1' ? 'J ' : ''}{item.february === '1' ? 'F ' : ''}{item.march === '1' ? 'M ' : ''}{item.april === '1' ? 'A ' : ''}{item.may === '1' ? 'M ' : ''}{item.june === '1' ? 'J ' : ''}{item.july === '1' ? 'J ' : ''}{item.august === '1' ? 'A ' : ''}{item.september === '1' ? 'S ' : ''}{item.october === '1' ? 'O ' : ''}{item.november === '1' ? 'N ' : ''}{item.december === '1' ? 'D ' : ''}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' || item.index === '2' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index up to start (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index from start (%): {item.increase2}</Card.Text>   
                          <Card.Text className="small tight-spacing">Last update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                          {item.single_year === '0' ? 
                          <>
                          </>
                          :
                          <br></br>
                          }                            
                          <Button variant="danger" onClick={() => handleIncomeDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleIncomeModityItem(item.originalIndex)}>Modify</Button>   
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openIncomeModal}>Add Expenditure</Button>
                <Button variant="secondary" onClick={closeAddIncomeRequirementsModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isIncomeModalOpen} onHide={closeIncomeModal}>
            <Modal.Header closeButton>
            <Modal.Title>Planned Expenditure (For Bond Ladder)</Modal.Title>
            </Modal.Header>
            <Modal.Body>


              {/* <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-right">Bond ladder uses specfic month/year start and end dates which need to be set by the user.</Tooltip>}>
              <Form.Label className="small fw-bold text-primary" style={{ marginTop: '4px'}}>Add specific inputs for bond ladder:</Form.Label>
              </OverlayTrigger> */}
              {/* <hr /> */}

              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
                <Form.Label htmlFor="incomeName" className="small">Description:</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeName" id="incomeName" className="form-control border-secondary" type="text" value={incomeName} onChange={(e) => setIncomeName(e.target.value)} placeholder="Name" />
                {errors.incomeName && <Alert className="alert alert-danger">{errors.incomeName}</Alert>}
                </Form.Group>

                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Expenditure amount in today's money</Tooltip>}>           
                <Form.Label htmlFor="incomeAmount" className="small">Amount (per year, current value):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeAmount" id="incomeAmount" className="form-control border-secondary" type="number" value={incomeAmount} onChange={(e) => setIncomeAmount(e.target.value)} placeholder="Amount" min="1" />
                  {errors.incomeAmount && <Alert className="alert alert-danger">{errors.incomeAmount}</Alert>}
                </Form.Group>

              <Row>
              <Col>
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Exact start date</Tooltip>}>          
                <Form.Label htmlFor="incomeStartDate" className="small">Exact start date:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="incomeStartDate"
                  id="incomeStartDate"
                  className="form-control border-secondary"
                  type="month" // Restricts to year and month
                  value={incomeStartDate.slice(0, 7)}
                  onChange={(e) => setIncomeStartDate(`${e.target.value}-01`)} // Sets the date with day as "01"
                  placeholder="yyyy-mm"
                />
                {errors.incomeStartDate && <Alert className="alert alert-danger">{errors.incomeStartDate}</Alert>}
              </Form.Group>
              </Col>
              <Col>
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Exact end date</Tooltip>}>           
                <Form.Label htmlFor="incomeEndDate" className="small">Exact end date:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="incomeEndDate"
                  id="incomeEndDate"
                  className="form-control border-secondary"
                  type="month" // Restricts to year and month
                  value={incomeEndDate.slice(0, 7)}
                  onChange={(e) => setIncomeEndDate(`${e.target.value}-01`)} // Sets the date with day as "01"
                  placeholder="yyyy-mm"
                />
                {errors.incomeEndDate && <Alert className="alert alert-danger">{errors.incomeEndDate}</Alert>}
              </Form.Group>
              </Col>
              </Row>



              <Form.Group as={Row} className="align-items-center">
                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-right">Select months in which expenditure occurs (annual amount is spread evenly across selected months; leave all months selected if expenditure is spread across all months)</Tooltip>}>
                <Form.Label className="small" style={{ marginTop: '4px'}}>Select months in which expenditure occurs:</Form.Label>
                </OverlayTrigger>

                <Col>
                  <Form.Label htmlFor="incomeJanuary" className="small">Jan:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeJanuary"
                  id="incomeJanuary"
                  checked={incomeJanuary === '1'}
                  onChange={(e) => setIncomeJanuary(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeFebruary" className="small">Feb:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeFebruary"
                  id="incomeFebruary"
                  checked={incomeFebruary === '1'}
                  onChange={(e) => setIncomeFebruary(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeMarch" className="small">Mar:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeMarch"
                  id="incomeMarch"
                  checked={incomeMarch === '1'}
                  onChange={(e) => setIncomeMarch(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeApril" className="small">Apr:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeApril"
                  id="incomeApril"
                  checked={incomeApril === '1'}
                  onChange={(e) => setIncomeApril(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeMay" className="small">May:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeMay"
                  id="incomeMay"
                  checked={incomeMay === '1'}
                  onChange={(e) => setIncomeMay(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeJune" className="small">Jun:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeJune"
                  id="incomeJune"
                  checked={incomeJune === '1'}
                  onChange={(e) => setIncomeJune(e.target.checked ? '1' : '0')}
                />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="align-items-center">
                <Col>
                  <Form.Label htmlFor="incomeJuly" className="small">Jul:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeJuly"
                  id="incomeJuly"
                  checked={incomeJuly === '1'}
                  onChange={(e) => setIncomeJuly(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeAugust" className="small">Aug:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeAugust"
                  id="incomeAugust"
                  checked={incomeAugust === '1'}
                  onChange={(e) => setIncomeAugust(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeSeptember" className="small">Sep:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeSeptember"
                  id="incomeSeptember"
                  checked={incomeSeptember === '1'}
                  onChange={(e) => setIncomeSeptember(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeOctober" className="small">Oct:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeOctober"
                  id="incomeOctober"
                  checked={incomeOctober === '1'}
                  onChange={(e) => setIncomeOctober(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeNovember" className="small">Nov:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeNovember"
                  id="incomeNovember"
                  checked={incomeNovember === '1'}
                  onChange={(e) => setIncomeNovember(e.target.checked ? '1' : '0')}
                />
                </Col>
                <Col>
                  <Form.Label htmlFor="incomeDecember" className="small">Dec:</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="incomeDecember"
                  id="incomeDecember"
                  checked={incomeDecember === '1'}
                  onChange={(e) => setIncomeDecember(e.target.checked ? '1' : '0')}
                />
                </Col>
              </Form.Group>
              

              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter percent of expenditure item to be included in bond ladder calculation (e.g. 100% = 100)</Tooltip>}>           
                <Form.Label htmlFor="incomePercentCovered" className="small">Percent covered by matched bonds(%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomePercentCovered" id="incomePercentCovered" className="form-control border-secondary" type="number" value={incomePercentCovered} onChange={(e) => setIncomePercentCovered(e.target.value)} placeholder="%" />
                {errors.percentCovered && <Alert className="alert alert-danger">{errors.percentCovered}</Alert>}
                </Form.Group>
                <Form.Group>
                </Form.Group>


                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the expenditure amount is expected to index with inflation each year. If you select 'differential rates' you can enter different rates of annual increase up to the age when the expenditure begins and after that age.</Tooltip>}>           
                <Form.Label htmlFor="incomeIndex" className="small">Index structure:</Form.Label>
                </OverlayTrigger>
                {/* <Form.Select name="incomeIndex" id="incomeIndex" className="form-control border-secondary" value={incomeIndex} onChange={(e) => setIncomeIndex(e.target.value)} defaultValue="1"> */}
                <Form.Select name="incomeIndex" id="incomeIndex" className="form-control border-secondary" value={incomeIndex} onChange={(e) => setIncomeIndex(e.target.value)}>
                  <option value="1">Indexed</option>
                  {/* <option value="2">Indexed (differential rates)</option> */}
                  {/* <option value="0">Not Indexed</option>
                  <option value="3">Not Indexed (differential rates)</option> */}
                </Form.Select>
                </Form.Group>

                {incomeIndex === '0' || incomeIndex === '1' ? 
                <>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. to index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                </Form.Group>
                </>
                :
                <>
                <Row>
                <Col>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. to index up to expenditure start age(%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>
                </Col>
                <Col>          
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount of the one-off contribution each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease2" className="small">Inc. to index from expenditure start age(%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease2" id="incomeIncrease2" className="form-control border-secondary" type="number" value={incomeIncrease2} onChange={(e) => setIncomeIncrease2(e.target.value)} placeholder="%" />
                {errors.incomeIncrease2 && <Alert className="alert alert-danger">{errors.incomeIncrease2}</Alert>}
                </Form.Group>
                </Col>        
                </Row>
                </>}

                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="incomeDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>                
                <Form.Control
                  name="incomeDate"
                  id="incomeDate"
                  className="form-control border-secondary"
                  type="month"
                  value={incomeDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setIncomeDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />   
                {errors.incomeDate && <Alert className="alert alert-danger">{errors.incomeDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleIncomeChange }>Save</Button>
                </Modal.Footer>
                </Modal>

            <div className="my-1" />           
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '4px' }} onClick={openBondMatchingModal}>Bond ladder matching options
              </Button>
            </div>
            <div className="my-2" />

            <Modal show={isBondMatchingModalOpen} onHide={closeBondMatchingModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Bond ladder matching options</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              
              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select bond matching option to each ladder step of expenditure:</Form.Label>
              
              <div>
                {/* USD Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="bond_matching"
                  id="bond_matching"
                  label="Bond maturing closest before (or in same month as) expenditure"
                  value="0"
                  checked={presetObject.bond_matching_option === '0'}
                  onChange={(e) => handleTextChange('bond_matching_option', e.target.value)}
                />
                {/* GBP Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="bond_matching"
                  id="bond_matching"
                  label="Bond maturing closest before expenditure (but where first month of expenditure in each ladder step is the month after the bond's maturity month)"
                  value="1"
                  checked={presetObject.bond_matching_option === '1'}
                  onChange={(e) => handleTextChange('bond_matching_option', e.target.value)}
                />
 
              </div>
              </Form.Group>

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeBondMatchingModal}>Close</Button>
              </Modal.Footer>
            </Modal>



            <div className="my-1" />           
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openDataChoiceModal}>Select USD or GBP bond ladder
              </Button>
            </div>
            <div className="my-2" />
            
            {/* Settings Modal */}
            <Modal show={isDataChoiceModalOpen} onHide={closeDataChoiceModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Select USD or GBP bond ladder</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              
              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select simulation currency denomination:</Form.Label>
              
              <div>
                {/* USD Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_usd"
                  label="USD - for US based investors. Equity returns are denominated in USD. Bond returns are represented by US Treasury. US inflation is used. US TIPS are used in index portfolio ladder constructor."
                  value="USD"
                  checked={presetObject.currency_set === 'USD'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
                {/* GBP Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_gbp"
                  label="GBP - for UK based investors. Equity returns are denominated in GBP. Bond returns are represented by UK Gilts. UK inflation is used. UK Index Linked Gilts are used in index portfolio ladder constructor."
                  value="GBP"
                  checked={presetObject.currency_set === 'GBP'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
              </div>
              </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeDataChoiceModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openBondChoiceModal}>Select / deselect bonds to be included in ladder
              </Button>
            </div>

            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Bond ladder analysis assumes no tax is applied to capital gain or notional inflation adjustment. Users will need to make their own adjustments for these taxes if applicable.</Tooltip>}>           
            <Form.Label htmlFor="ladder_tax" className="fw-light small">Enter tax rate (%) to be applied to bond coupons:</Form.Label>
            </OverlayTrigger>
            <Form.Control name="ladder_tax" id="ladder_tax" className="form-control bg-secondary border-secondary text-light" type="number" value={presetObject.ladder_tax} onChange={(e) => handleObjectChange('ladder_tax', e.target.value)} />
            {errors.ladderTax && <Alert className="alert alert-danger">{errors.ladderTax}</Alert>}
            </Form.Group>

            <Modal show={isBondChoiceModalOpen} onHide={closeBondChoiceModal}>
            <Modal.Header closeButton>
            <Modal.Title>Select / deselect bonds to be included in ladder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup>
            {bondList.map((bond, index) => (
              <ListGroup.Item key={bond.name}>
                <Form.Check
                  type="checkbox"
                  label={`${bond.name}`}
                  checked={bond.include}
                  onChange={() => handleBondInclude(index)}
                />
              </ListGroup.Item>
            ))}
            </ListGroup>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeBondChoiceModal}>Close</Button>
            </Modal.Footer>
            </Modal>


            <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalmessage}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isResetModalOpen} onHide={closeResetModal }>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalResetMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeResetModal }>Cancel</Button>
              <Button variant="danger" onClick={resetPlan }>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isRetrieveModalOpen} onHide={closeRetrieveModal} centered size={isSmallScreen ? 'sm' : 'lg'}>
            <Modal.Header closeButton>
            <Modal.Title>User Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {plans.length > 0 ? (
            <Table hover className="horizontal-border-only">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '45%' }}>Plan title</th>
                  <th style={{ width: '25%' }}>Actions</th> 
                  <th style={{ width: '25%' }}>Last update</th> 
                </tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <tr key={plan.id}>
                    <td>{index + 1}</td>
                    <td>{plan.plan_title}</td>
                    <td>
                      <Button variant="success" onClick={() => retrievePlan(plan.id)} className="me-1 mb-1 mb-lg-0" style={{ width: '80px' }}>Retrieve</Button>
                      <Button variant="danger" onClick={() => handleOpenDeleteModal(plan.id)}className="me-1" style={{ width: '80px' }}>Delete</Button>
                    </td>
                    <td>{new Date(plan.plan_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            ) : (
            <p>No plans found.</p>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeRetrieveModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={showDateModal} onHide={() => handleDateModalClose(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Update Portfolio Date</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Do you want to save this plan's 'Last update' date as today's date?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {handleDateModalClose(false)}}>No, keep existing</Button>
                <Button variant="secondary" onClick={() => {handleDateModalClose(true)}}>Yes, update date</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body><p>Are you sure you want to delete this plan? This action cannot be undone.</p></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
            <Button variant="danger" onClick={() => deletePlan(selectedPlanId)}>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isReLoginModalOpen} onHide={closeReLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Error connecting to server. Action could not be completed. Please try re-logging in and re-trying action.</p>
                <ReLoginForm onClose={closeReLoginModal} />
              </Modal.Body>
            </Modal>

          {/* this is stuffing to help the onscreen formatting */}
          {isSmallScreen ? <></> : <><br /><br /></>}

          </Col>

          <Col sm={8} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>
          <Row>
            <Col xs="6" sm="6">
            <div className="d-flex align-items-center">
            <p className="lead mb-0">Ladder analysis:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">Analysis shows amount of each index linked bond issue required to maturity match against user's planned expenditure schedule. Cash balance is to cover expenditure up until earliest maturing bond. Bond market prices are indicative based on market yield curve (which may a number of days out of date - see 'Asset Returns' to see the date the curve is from). To implement a bond ladder, users should get the amounts of each issue required in nominal (par value) terms and then do a 'live' conversion with 'live' market prices to get the exact amounts of each bond that they need to hold. The bond ladder calculation assumes that the interest coupon from each bond issue held is used to cover expenditure in the period in which the coupon is received.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>


            </Col>
            <Col xs="6" sm="6">
            </Col>


            {/* <Col sm="4">
              <div className="fs-1 bg-dark text-light">{bondDiscountCost?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Sum (indicative market) cost of bonds required ({currencyIcon}):</div>
            </Col> */}
            
            {/* <Row>
            <Col sm="4">
            <div style={{ marginTop : '10px' }}>
            <Button variant="success" onClick={runAnalysis}>Run analysis</Button> 
            </div>
            </Col>

            <Col sm="8">
              <Button variant="primary" onClick={() => downloadCSV(bondLadder)} style={{ marginTop : '10px' }}>
                <FaDownload style={{ marginRight: '8px' }} />
                Download ladder as CSV
              </Button>
            </Col>
            </Row> */}

            <Row className="align-items-center">
              <Col sm="4">
                <div style={{ marginTop: '10px' }}>
                  <Button variant="success" onClick={runAnalysis}>Run analysis</Button>
                </div>
              </Col>

              <Col sm="8" className="d-flex justify-content-md-end justify-content-start">
                <Button variant="primary" onClick={() => downloadCSV(bondLadder)} style={{ marginTop: '10px' }}>
                  <FaDownload style={{ marginRight: '8px' }} />
                  Download ladder as CSV
                </Button>
              </Col>
            </Row>

            <div style={{ overflowX: 'auto' }}>
            <Table striped bordered hover variant="dark" style={{ marginTop : '24px', marginBottom : '72px'}}>
              <thead>
                <tr>
                  <th style={{ width: '40%' }}>Issue</th>
                  <th style={{ width: '20%' }}>Notional required (par value, {currencyIcon})</th>
                  <th style={{ width: '20%' }}>Price (indicative)</th>
                  <th style={{ width: '25%' }}>Amount required (indicative market price, {currencyIcon})</th>
                </tr>
              </thead>
              <tbody>
                {bondLadder?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.bond_name}</td>
                    <td>{item.bond_notional}</td>
                    <td>{item.bond_price}</td>
                    <td>{item.bond_market}</td>
                  </tr>
                )) || <></>}
              </tbody>
            </Table>
            </div>
            {/* {isSmallScreen ? <></> : <><br /><br /><br /></>} */}
          </Row>
          </Col>

          </Row>
      </Container>
      </div>
    )
};

export default Ladder

