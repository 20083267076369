import React from 'react'

function ValidateAnnuityForm (presetObject, annuityName, annuityYear, annuityAmount, annuityPrice, annuityIncrease, annuityDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (annuityName.length > 255) {newErrors.annuityName = 'Name description must be less than 255 characters'}
    if (annuityName === undefined || String(annuityName).trim() === '') {newErrors.annuityName = 'Add a name'}
    if (annuityAmount < 0) {newErrors.annuityAmount = 'Can not be negative'}
    if (isNaN(annuityAmount) || annuityAmount === null || annuityAmount === undefined || String(annuityAmount).trim() === '') {newErrors.annuityAmount = 'Can not have empty fields. Enter a value.'}

    if (annuityPrice < 0) {newErrors.annuityPrice = 'Can not be negative'}
    if (isNaN(annuityPrice) || annuityPrice === null || annuityPrice === undefined || String(annuityPrice).trim() === '') {newErrors.annuityPrice = 'Can not have empty fields. Enter a value.'}

    if (isNaN(annuityIncrease) || annuityIncrease === null || annuityIncrease === undefined || String(annuityIncrease).trim() === '') {newErrors.annuityIncrease = 'Can not have empty fields. Enter a value.'}
    if (annuityYear < presetObject.start_simulation_age || annuityYear > presetObject.end_simulation_age) {newErrors.annuityYear = 'Start age must be inside range of simulation start age and end age'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateAnnuityForm


