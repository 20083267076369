import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 
import '../styles/mystyles.css'
import { FaInfoCircle,FaExclamationCircle } from 'react-icons/fa'; // Font Awesome info icon
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';

function MasterLineChart(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height
  const [datasets, setDatasets] = useState([]);
  const datasetLabels = ['0th', '10th', '25th', '50th', '75th', '90th', '100th'];
  const backgroundColors = [
    'rgba(60, 179, 113, 0.2)', 
    'rgba(102, 205, 170, 0.3)',  
    'rgba(144, 238, 144, 0.4)'  
  ];
  const borderColors = [
    'rgba(175, 215, 255, 0.5)',  
    'rgba(153, 204, 255, 0.75)',  
    'rgba(102, 178, 255, 1)',
    'rgba(51, 153, 255, 1)', 
    'rgba(102, 178, 255, 1)',
    'rgba(153, 204, 255, 0.75)',
    'rgba(175, 215, 255, 0.5)',
  ];


  useEffect(() => {
    const updateChartHeight = () => {
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, [props.heightratio]);

  // Update datasets based on props data
  useEffect(() => {

    let newDatasets;
    if (props.percentile === false) {
      if(props.multiplelines === true) {
    const newDatasets = props.data.map((dataArray, datasetIndex) => ({
      label: `Scenario ${datasetIndex + 1}`,
      data: dataArray,
      borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.4)`,
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.2)`,

    }));
    setDatasets(newDatasets);} else {
    const newDatasets = [{
      label: 'SWR',
      data: props.data,
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }];
    setDatasets(newDatasets);
    }
  } else {
      const newDatasets = props.data.map((dataArray, datasetIndex) => {
        let backgroundColor;
        if (datasetIndex === 1 || datasetIndex === 6) {
          backgroundColor = backgroundColors[0];
        } else if (datasetIndex === 2 || datasetIndex === 5) {
          backgroundColor = backgroundColors[1];
        } else {
          backgroundColor = backgroundColors[2];
        }
        return {
          label: datasetLabels[datasetIndex],
          data: dataArray,
          borderColor: borderColors[datasetIndex],
          backgroundColor: datasetIndex === 0 ? 'rgba(0,0,0,0)' : backgroundColor,
          fill: datasetIndex === 0 ? false : '-1'
        };
      });
    setDatasets(newDatasets);};


    
  }, [props.data]);

  const chartData = {
    labels: props.labels,
    datasets: datasets,
  };

  const options = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label || '';
            let value = tooltipItem.raw.toFixed(2);  // Set decimal places to 1
            
            return label + ': ' + value;
          }
        }
        // callbacks: {
        //   label: function (context) {
        //     let value = context.raw; 
        //     value = Math.round(value);
        //     return value.toLocaleString();
        //   },
        // },
      },
      legend: {
        display: props.percentile,
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        color: 'white',
        font: {
          size: 14, // Set the font size for the title
        },
        text: props.title
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: props.yaxistitle,
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: props.labels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set y-axis grid color to a light grey with some opacity
        },
        beginAtZero: true,
        ticks: {
          color: 'white',
          beginAtZero: true,
 
          callback: function(value) {
            if (value >= 1000000 && value < 10000000 || value <= -1000000 && value > -10000000) {
              return `${(value / 1000000).toFixed(1)}m`; // Show 1 decimal point for values between 1m and 10m
            } else if (value >= 10000000 || value <= -10000000) {
              return `${(Math.round(value / 1000000)).toLocaleString()}m`; // Show whole numbers for values 10m and above
            } else if (value >= 1000 && value < 10000 || value <= -1000 && value > -10000) {
              return `${(value / 1000).toFixed(1)}k`; // Show 1 decimal place for values between 1k and 10k
            } else if (value >= 10000 || value <= -10000) {
              return `${(Math.round(value / 1000)).toLocaleString()}k`; // Show whole numbers for values 10k and above
            }
            return value.toLocaleString(); // For values below 1k
          },

        },
      },
    },
  };

  return (
    <div>
      {isSmallScreen ? (
        <div style={{ width: '100%', height: '300px' }}>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <div style={{ width: '100%', height: `${chartHeight}px` }}>
          <Line data={chartData} options={options} />
        </div>
      )}
      {/* <FaInfoCircle size="2.5em" /> */}
      
      {props.portvaluedistchart && props.data_direction === 'forward' && props.asset_mix_equity < 100 ? (
      <>
      <div className="d-flex align-items-center">
      <>Notice: </>
      <OverlayTrigger
        placement="right"
        overlay={
        <Tooltip id="tooltip-right">Bonds in portfolio are treated as being cash-flow matched against withdrawals. Possible value movements in bonds before maturity are not included in the simulation.</Tooltip>}>
        <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
        <FaExclamationCircle style={{ color: '#adb5bd', fontSize: '1.5rem' }} />
        </span>
      </OverlayTrigger>

      {props.simulation_years > 40 && (
        <OverlayTrigger
        placement="right"
        overlay={
        <Tooltip id="tooltip-right">For market implied bond returns over 40 years, the simulation model assumes forward interest rates beyond this point equal the current 40 year forward rate (30 years in case of US bonds).</Tooltip>}>
        <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
        <FaExclamationCircle style={{ color: '#adb5bd', fontSize: '1.5rem' }} />
        </span>
      </OverlayTrigger>        
      )}
      </div>
      <br></br>
      </>
      ) : (<br />)}
    </div>
  );
};

export default MasterLineChart;


