import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { usePresetStore } from "../../components/datastore.js";
import useLogout from '../../utils/logout';
import Modal from 'react-bootstrap/Modal';
import LoginForm from '../../views/login';
import ResetForm from '../../views/reset';
import RegisterForm from '../../views/register';
import LogoBanner from "../../components/logobanner.png";

const Navigbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Start as collapsed
  const location = useLocation(); // Get current route

  const logInState = useAuthStore().isLoggedIn();
  const userId = useAuthStore().user();

  const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
  const resetPlanId = usePresetStore((state) => state.resetPlanId);


  // State for Modals
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
    resetPlanId();
    resetPresetObject();
    setLogoutMessage('You have been logged out');
    setShowLogoutModal(true);
  };

  const handleShowLogin = () => setShowLoginModal(true);
  const handleShowRegister = () => setShowRegisterModal(true);
  const handleShowReset = () => setShowResetModal(true);
  const handleCloseLogout = () => {
    setShowLogoutModal(false);
    window.location.reload();}
  const handleCloseLogin = () => setShowLoginModal(false);
  const handleCloseRegister = () => setShowRegisterModal(false);
  const handleCloseReset = () => setShowResetModal(false);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 992);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="py-0">
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '0' }}>
       
          {(location.pathname !== '/' && !isSmallScreen) && ( // Show logo only when not on '/' and isSmallScreen is false
            <Navbar.Brand as={Link} to="/" className="ms-0" style={{ paddingLeft: '10px', paddingBottom: '0' }}>
              <img src={LogoBanner} alt="Logo" style={{ width: '200px', height: 'auto' }} />
            </Navbar.Brand>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex align-items-center">

              {(isSmallScreen) && (<Nav.Link as={Link} to="/">Introduction</Nav.Link>)}
              <Nav.Link as={Link} to="/calc">Simulation Calculator</Nav.Link>
              <Nav.Link as={Link} to="/ladder">Index Bond Ladder</Nav.Link>
              <NavDropdown title="Learning" id="basic-nav-dropdown" menuVariant="dark" align="end">
                <NavDropdown.Item as={Link} to="/methodology">Methodology, Concepts & Data Sources</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/strategies">Withdrawal Approaches</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/returns">Historic Asset Returns</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/forwardreturns">Forward Asset Returns</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/aboutbondladder">Optimising Plan Risk</NavDropdown.Item>
              </NavDropdown>

              {!logInState && (
                <NavDropdown title="Manage Account" id="basic-nav-dropdown" menuVariant="dark" style={{ paddingRight: '5px' }} align="end">
                  <NavDropdown.Item as="span" onClick={handleShowLogin}>Login</NavDropdown.Item>
                  <NavDropdown.Item as="span" onClick={handleShowRegister}>Register</NavDropdown.Item>
                  <NavDropdown.Item as="span" onClick={handleShowReset}>Reset Password</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/terms">Terms</NavDropdown.Item>
                </NavDropdown>
              )}

              {logInState && (
                <NavDropdown title="Manage Account" id="basic-nav-dropdown-loggedin" menuVariant="dark" style={{ paddingRight: '5px' }} align="end">
                  <NavDropdown.Item as="span" onClick={handleLogout}>Logout</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/terms">Terms</NavDropdown.Item>
                </NavDropdown>
              )}

              {logInState && (
                <Nav.Link as="span" className="text-light">{userId.username}</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* Modals for logout, login, reset, and register */}
      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={handleCloseLogout}>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>{logoutMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseLogout}>Continue</Button>
        </Modal.Footer>
      </Modal>

      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm onClose={handleCloseLogin} />
        </Modal.Body>
      </Modal>

      {/* Reset Modal */}
      <Modal show={showResetModal} onHide={handleCloseReset}>
        <Modal.Header closeButton>
          <Modal.Title>Reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResetForm onClose={handleCloseReset} />
        </Modal.Body>
      </Modal>

      {/* Register Modal */}
      <Modal show={showRegisterModal} onHide={handleCloseRegister}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm onClose={handleCloseRegister} />
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Navigbar;

