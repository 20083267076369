
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; 

function DistTypeChart(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height
  const [title, setTitle] = useState();

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    setTitle(`Development of average income by type (current ${props.currencysymbol}, across all back-test cycles, net of tax)`);
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  // const labels = Array.from({ length: props.data2 }, (_, index) => `${index + 1}`);
  const labels = props.data5;
  
  const datasetLabels = ['State Pension', 'Occupational Pension', 'Purchased Annuity', 'Portfolio Withdrawal', "'Bonus' Portfolio Withdrawal"];

  const backgroundColors = [
    'rgba(30, 60, 150, 1)',   // Dark blue
    'rgba(60, 90, 180, 1)',   // Medium-dark blue
    'rgba(90, 120, 210, 1)',  // Medium blue
    'rgba(120, 150, 240, 1)', // Light blue
    'rgba(180, 210, 255, 1)', // Very light blue
];

  const borderColors = [
    'rgba(30, 60, 150, 1)',   // Dark blue
    'rgba(60, 90, 180, 1)',   // Medium-dark blue
    'rgba(90, 120, 210, 1)',  // Medium blue
    'rgba(120, 150, 240, 1)', // Light blue
    'rgba(180, 210, 255, 1)', // Very light blue
];

  const datasets = props.data.map((dataArray, datasetIndex) => ({
    label: datasetLabels[datasetIndex],
    data: dataArray,
    borderColor: borderColors[datasetIndex],
    backgroundColor: backgroundColors[datasetIndex],
  }));

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'white',
        }, 
      },
      title: {
        display: true,
        font: {
          size: 14, // Set the font size for the title
        },
        text: title,
        color: 'white',
      },
    },
    scales: {
      x: {
        stacked: true, // Enable stacking for x-axis
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: 'In year beginning at age',
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: labels,
      },
      y: {
        stacked: true, // Enable stacking for y-axis
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set y-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set y-axis ticks color to white
          beginAtZero: true,
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {
              return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'; 
            } else if (value >= 1000000 || value <= -1000000) {
              return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm';
            } else if (value >= 1000 || value <= -1000) {
              return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k';
            } else {
              return '' + value.toString();
            }
          },
        },
      },
    },
  };

  return (
    <div>
      {isSmallScreen ? (
        <div style={{ width: '100%', height: '300px' }}>
          <Bar data={chartData} options={options} /> 
        </div>
      ) : (
        <div style={{ width: '100%', height: `${chartHeight}px` }}>
          <Bar data={chartData} options={options} /> 
          <br></br>
        </div>
      )}
    <br></br>
    </div>
  );
};

export default DistTypeChart;


