import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'; // Import Modal from React-Bootstrap
import useReset from '../utils/reset';
import { useAuthStore } from '../store/auth';
import ButtonExample from "../components/loadingbutton.js";

const ResetForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const [loading, setLoading] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Processing...');
    const [errorModal, setErrorModal] = useState(false); // State to control error modal visibility

    const resetForm = () => {
        setUsername('');
        setErrorMessage(''); // Reset the error message
    };
 
    const { reset } = useReset();

    const handleReset = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { error } = await reset(username);
        setLoading(false);

        if (error) {
            if (error.username) {
                setErrorMessage(error.username); // Handle specific username errors
            } else {
                resetForm();
                setErrorModal(true); // Show error modal if unhandled error occurs
            }
        } else {
            resetForm();
            onClose(); // Close the modal on successful reset
        }
    };

    // Close the error modal
    const handleCloseErrorModal = () => {
        setErrorModal(false);
    };

    return (
        <>
            <section>
                <Form onSubmit={handleReset}>
                    <Form.Group>
                        <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
                        <Form.Control 
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessage}
                        </div>
                    )}
                    <br />
                    {loading ? (
                        <ButtonExample data={buttonlabel} />
                    ) : (
                        <Button variant="success" type="submit">Send reset request</Button>
                    )}
                </Form>
            </section>

            {/* Error modal to show in case of unhandled error */}
            <Modal show={errorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Something went wrong. Contact administrator if issue persists.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ResetForm;


// import React, { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import useReset from '../utils/reset';
// import { useAuthStore } from '../store/auth';
// import ButtonExample from "../components/loadingbutton.js";

// const ResetForm = ({ onClose }) => {
//     const [username, setUsername] = useState('');
//     const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
//     const [loading, setLoading] = useState(false);
//     const [buttonlabel, setButtonlabel] = useState('Processing...')

//     const resetForm = () => {
//         setUsername('');
//         setErrorMessage(''); // Reset the error message
//     };
 
//     const { reset } = useReset();

//     const handleReset = async (e) => {
//         e.preventDefault();
//         setLoading(true)
//         const { error } = await reset(username);
//         setLoading(false)
//         if (error) {
//             setErrorMessage(error); // Set the error message to be displayed
//         } else {
//             resetForm();
//             onClose(); // Close the modal on successful login
//             };
//         }
//     // };

//     return (
//         <section>

//             <Form onSubmit={handleReset}>
//                 <Form.Group>
//                     <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
//                     <Form.Control 
//                         type="text"
//                         id="username"
//                         name="username"
//                         value={username}
//                         onChange={(e) => setUsername(e.target.value)}
//                     />
//                 </Form.Group>
//                 {errorMessage && (
//                     <div style={{ color: 'red', marginTop: '10px' }}>
//                         {errorMessage}
//                     </div>
//                 )}
//                 <br />
//                 {/* <Button variant="success" type="submit">Send reset request</Button> */}
//                 {loading ? (<ButtonExample data={buttonlabel}/>) : (<Button variant="success" type="submit">Send reset request</Button>)}

//             </Form>

//         </section>
//     );
// };

// export default ResetForm;


