import React, { useEffect, useState, Component } from 'react'
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, LinearScale);

const ReturnVarianceChart = (props) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  var my_font_size = isSmallScreen ? 8 : 12; 
  var my_font_size_small = isSmallScreen ? 6: 10;

  const data = {
    datasets: [
      {
        label: '20 Year Holding',
        data: props.data.twenty,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        showLine: true,
        datalabels: {
          display: (context) => context.dataIndex === 0 || context.dataIndex === props.data.twenty.length - 1,
          align: 'end',
          anchor: 'end',
          color: 'white',
          font: {
            size: my_font_size_small,
          },
          formatter: (value, context) => context.dataIndex === 0 ? '100% EQ, 0% FI' : '0% EQ, 100% FI',
        }
      },
      {
        label: '10 Year Holding',
        data: props.data.ten,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        showLine: true,
        datalabels: {
          display: (context) => context.dataIndex === 0 || context.dataIndex === props.data.ten.length - 1,
          align: 'end',
          anchor: 'end',
          color: 'white',
          font: {
            size: my_font_size_small,
          },
          formatter: (value, context) => context.dataIndex === 0 ? '100% EQ, 0% FI' : '0% EQ, 100% FI',
        }
      },

      {
        label: '3 Year Holding',
        data: props.data.one,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        showLine: true,
        datalabels: {
          display: (context) => context.dataIndex === 0 || context.dataIndex === props.data.one.length - 1,
          align: 'end',
          anchor: 'end',
          color: 'white',
          font: {
            size: my_font_size_small,
          },
          formatter: (value, context) => context.dataIndex === 0 ? '100% EQ, 0% FI' : '0% EQ, 100% FI',
        }
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          font: {size : my_font_size,},
        },
        title: {
          display: true,
          text: 'Standard Deviation of Annualised Real Returns (%)',
          color: 'white',
          font: {
            size: my_font_size, // Set the font size for the axis title
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Set y-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set y-axis ticks color to white
          font: {size : my_font_size,},
        },
        title: {
          display: true,
          text: 'Average Annualised Real Return (%)',
          color: 'white',
          font: {
            size: my_font_size, // Set the font size for the axis title
          },
        },
      },
    },
    plugins: {

      legend: {
        labels: {
          color: 'white',
          font: {size : my_font_size,},
        },
      },

      datalabels: {
        display: false, // Disable datalabels by default
      },
    },
  };

  return (
    <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Scatter data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    ) : (
      <div style={{ width: '100%', height: '400px' }}>
        <Scatter data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    )}
    </div>
  );
};

export default ReturnVarianceChart;

