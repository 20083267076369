import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'; // Import Modal from React-Bootstrap
import useLogin from '../utils/login';
import { useAuthStore } from '../store/auth';

const LoginForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const [errorModal, setErrorModal] = useState(false); // State to control error modal visibility
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

    const [showLogInWelcome, setShowLogInWelcome] = useState(false);

    // not sure what this useEffect is for and how LoginForm can be enacted if user is already logged in
    // useEffect(() => {
    //     if (isLoggedIn()) {
    //         onClose(); // Close the modal if already logged in
    //     }
    // }, [isLoggedIn, onClose]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
        setErrorMessage(''); // Reset the error message
    };

    const { login } = useLogin();

    const handleLogin = async (e) => {
        e.preventDefault();
        const { error } = await login(username, password);

        if (error) {
            console.log(error)
            if (error.username) {
                setErrorMessage(error.username);
            } else if (error.password) {
                setErrorMessage(error.password);
            } else if (error.detail) {
                setErrorMessage(error.detail);
            }
            else {
                setErrorModal(true);
            }
        } else {
            resetForm();
            openLogInWelcome();
        }
    };

    // Close the error modal
    const handleCloseErrorModal = () => {
        resetForm();
        setErrorModal(false);
    };

    const openLogInWelcome = () => {
        setShowLogInWelcome(true);
    };

    const closeLogInWelcome = () => {
        setShowLogInWelcome(false);
        onClose(); // Trigger onClose only when the login welcome modal is closed by the user
    };

    return (
        <>
            <section>
                <Form onSubmit={handleLogin}>
                    <Form.Group>
                        <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
                        <Form.Control 
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="username"
                            placeholder="Username"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="password" className="small">Password:</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                            placeholder="Password"                
                        />
                    </Form.Group>
                    {/* Display error message */}
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessage}
                        </div>
                    )}
                    <br />
                    <Button variant="success" type="submit">
                        Log In
                    </Button>
                </Form>
            </section>

            {/* Error modal to show in case of unhandled error */}
            <Modal show={errorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Something went wrong. Contact administrator if issue persists.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Welcome modal */}
            <Modal show={showLogInWelcome} onHide={closeLogInWelcome}>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are logged in.</p>
                    <p>You can now save and retrieve plans using the Manage Plan menu button in Simulation Calculator.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={closeLogInWelcome}>Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LoginForm;

{/* <p>
    You can now save and retrieve plans under 
    <span 
    className="btn btn-success" 
    style={{ 
        cursor: 'default', 
        pointerEvents: 'none', 
        marginLeft: '5px', 
        marginRight: '5px', 
        marginBottom: '4px',
        padding: '2px 6px',  // Reduces the padding to make it smaller
        fontSize: 'inherit'   // Keeps the text size the same as the surrounding text
    }}>
    Manage Plan
    </span> 
    in the Simulation Calculator.
</p> */}