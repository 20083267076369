import React from 'react'

function ValidateAssetDeduction (deductionEqGA, deductionBondGA, deductionIndexGA, deductionEqTE, deductionBondTE, deductionIndexTE, deductionEqTD, deductionBondTD, deductionIndexTD, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (deductionEqGA < 0) {newErrors.deductionEqGA = 'Can not be negative'}
    if (isNaN(deductionEqGA) || deductionEqGA === null || deductionEqGA === undefined || String(deductionEqGA).trim() === '') {newErrors.deductionEqGA = 'Can not have empty fields. Enter a value.'}
    if (deductionBondGA < 0) {newErrors.deductionBondGA = 'Can not be negative'}
    if (isNaN(deductionBondGA) || deductionBondGA === null || deductionBondGA === undefined || String(deductionBondGA).trim() === '') {newErrors.deductionBondGA = 'Can not have empty fields. Enter a value.'}
    if (deductionIndexGA < 0) {newErrors.deductionIndexGA = 'Can not be negative'}
    if (isNaN(deductionIndexGA) || deductionIndexGA === null || deductionIndexGA === undefined || String(deductionIndexGA).trim() === '') {newErrors.deductionIndexGA = 'Can not have empty fields. Enter a value.'}

    if (deductionEqTE < 0) {newErrors.deductionEqTE = 'Can not be negative'}
    if (isNaN(deductionEqTE) || deductionEqTE === null || deductionEqTE === undefined || String(deductionEqTE).trim() === '') {newErrors.deductionEqTE = 'Can not have empty fields. Enter a value.'}
    if (deductionBondTE < 0) {newErrors.deductionBondTE = 'Can not be negative'}
    if (isNaN(deductionBondTE) || deductionBondTE === null || deductionBondTE === undefined || String(deductionBondTE).trim() === '') {newErrors.deductionBondTE = 'Can not have empty fields. Enter a value.'}
    if (deductionIndexTE < 0) {newErrors.deductionIndexTE = 'Can not be negative'}
    if (isNaN(deductionIndexTE) || deductionIndexTE === null || deductionIndexTE === undefined || String(deductionIndexTE).trim() === '') {newErrors.deductionIndexTE = 'Can not have empty fields. Enter a value.'}

    if (deductionEqTD < 0) {newErrors.deductionEqTD = 'Can not be negative'}
    if (isNaN(deductionEqTD) || deductionEqTD === null || deductionEqTD === undefined || String(deductionEqTD).trim() === '') {newErrors.deductionEqTD = 'Can not have empty fields. Enter a value.'}
    if (deductionBondTD < 0) {newErrors.deductionBondTD = 'Can not be negative'}
    if (isNaN(deductionBondTD) || deductionBondTD === null || deductionBondTD === undefined || String(deductionBondTD).trim() === '') {newErrors.deductionBondTD = 'Can not have empty fields. Enter a value.'}
    if (deductionIndexTD < 0) {newErrors.deductionIndexTD = 'Can not be negative'}
    if (isNaN(deductionIndexTD) || deductionIndexTD === null || deductionIndexTD === undefined || String(deductionIndexTD).trim() === '') {newErrors.deductionIndexTD = 'Can not have empty fields. Enter a value.'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateAssetDeduction
