import React, { useState } from 'react'

function validatesanserrorsForm (presetObject) {
    const newErrors = {};
    if (presetObject.equity_tax > 100 || presetObject.equity_tax < 0) {newErrors.equity_tax = 'Enter 0-100%'};
    if (presetObject.fees > 200 || presetObject.fees < 0) {newErrors.fees = 'Enter 0-200 basis points'};
    if (presetObject.draw_tax > 100 || presetObject.draw_tax < 0) {newErrors.draw_tax = 'Enter 0-100%'};
    if (presetObject.bond_tax > 100 || presetObject.bond_tax < 0) {newErrors.bond_tax = 'Enter 0-100%'};
    if (presetObject.ladder_tax > 100 || presetObject.ladder_tax < 0) {newErrors.bond_tax = 'Enter 0-100%'};
    if (presetObject.gilt_coupon < 0 || presetObject.gilt_coupon > 5) {newErrors.gilt_coupon = 'Can not be negative'};
    if (presetObject.ilb_coupon < 0 || presetObject.ilb_coupon > 5) {newErrors.ilb_coupon = 'Can not be negative'};
    if (presetObject.asset_mix_equity > 100 || presetObject.asset_mix_equity < 0) {newErrors.asset_mix_equity = 'Enter 0-100%'};
    if (presetObject.asset_mix_bond > 100 || presetObject.asset_mix_bond < 0) {newErrors.asset_mix_bond = 'Enter 0-100%'};
    if (presetObject.asset_mix_index_bond > 100 || presetObject.asset_mix_index_bond < 0) {newErrors.asset_mix_index_bond = 'Enter 0-100%'};
    if (presetObject.target_withdrawal_percent < 0 || presetObject.target_withdrawal_percent > 100) {newErrors.target_withdrawal_percent = 'Enter 0-100%'};
    if (presetObject.annuity_tax_rate < 0 || presetObject.annuity_tax_rate > 99) {newErrors.annuity_tax_rate = 'Enter 0-99%'};
    if (presetObject.annuity_tax_rate2 < 0 || presetObject.annuity_tax_rate2 > 99) {newErrors.annuity_tax_rate2 = 'Enter 0-99%'};
    if (presetObject.annuity_tax_rate3 < 0 || presetObject.annuity_tax_rate3 > 99) {newErrors.annuity_tax_rate3 = 'Enter 0-99%'};
    if (presetObject.start_simulation_age < 0) {newErrors.start_simulation_age = 'Can not be negative'};
    if (presetObject.end_simulation_age < 0) {newErrors.start_simulation_age = 'Can not be negative'};
    if (presetObject.yale_weighting < 0 || presetObject.yale_weighting > 100) {newErrors.yale_weighting = 'Enter 0-100%'};
    if (presetObject.vanguard_decrease_floor < 0) {newErrors.vanguard_decrease_floor = 'Can not be negative'};
    if (presetObject.vanguard_increase_ceiling < 0) {newErrors.vanguard_increase_celing = 'Can not be negative'};

    if (Object.keys(newErrors).length > 0) {return false};
    return true;
};

export default validatesanserrorsForm
