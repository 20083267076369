import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import '../styles/mystyles.css'

function SafeFundingByAssetMixChart(props) {
  
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(window.innerWidth * props.heightratio); // Initial chart height
  const [xAxisText, setXAxisText] = useState('')

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  useEffect(() => {
    if (props.data2 === '0') {setXAxisText('% equity (vs. conventional bonds) in portfolio')} else {setXAxisText('% equity (vs. index linked bonds) in portfolio')}
  }, [props.data2]);


  const labels = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'SWR',
        data: props.data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
    legend: {
      display: false,
      },
      title: {
        display: true,
        font: {
          size: 14, // Set the font size for the title
        },
        text: `Safe initial funding level (${props.currencysymbol} current value) by asset mix for constant withdrawal strategy`,
        color: 'white',
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          // text: '% equity (vs bonds) in portfolio',
          text: xAxisText,
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: labels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        beginAtZero: true,
        ticks: {
          color: 'white',
          beginAtZero: true,
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
            else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
            else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
            else {return '' + value.toString()};
          },},
      },
    },
  };

  return (
    <div>
      {isSmallScreen ? (
        <div style={{ width: '100%', height: '300px' }}>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <div style={{ width: '100%', height: `${chartHeight}px` }}>
          <Line data={chartData} options={options} />
        </div>
      )}
      <br></br>
    </div>
  );
};

export default SafeFundingByAssetMixChart;


