import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function ButtonExample({data}) {
  return (
    <>
      <Button variant="success" style={{ marginRight: '5px', marginBottom: '5px' }} disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        {data}
      </Button>
    </>
  );
}

export default ButtonExample;

