import React, { useEffect, useState, Component } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
// import CanvasJSReact from 'canvasjs';

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const BoxAndWhiskerPlot = (chartData) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  useEffect(() => {
    const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);

    var my_font_size = isSmallScreen ? 8 : 12; 

    // const options = {
    //   responsive: true,
    //   // maintainAspectRatio: isLargeScreen,
    //   maintainAspectRatio: false,
    //   theme: "dark2",
    //   backgroundColor: "#212529",
    //   animationEnabled: true,
    //   axisX: {
    //     labelFontSize: my_font_size, // Change this value to adjust the font size
    //     // other x-axis options...
    //   },
    //   axisY: {
    //     title: "% Annual Return",
    //     titleFontSize: my_font_size,
    //     labelFontSize: my_font_size,
    //   },
    //   data: [{
    //     type: "boxAndWhisker",
    //     // color: "white",
    //     // lineColor: "white",
    //     yValueFormatString: "#,##0.# \"%\"",
    //     // whiskerWidth: 10,
    //     dataPoints: [
    //       // dataset with average as middle point
    //       // { label: "Equity nominal",  y: [chartData.data.equity_nominal[0]*100, chartData.data.equity_nominal[1]*100, chartData.data.equity_nominal[3]*100, chartData.data.equity_nominal[4]*100, chartData.data.equity_nominal_avg*100], whiskerColor: "white"},
    //       // { label: "Bond (10y) nominal",  y: [chartData.data.bond_nominal[0]*100, chartData.data.bond_nominal[1]*100, chartData.data.bond_nominal[3]*100, chartData.data.bond_nominal[4]*100, chartData.data.bond_nominal_avg*100], whiskerColor: "white"},
    //       // { label: "Inflation (CPI)",  y: [chartData.data.cpi_nominal[0]*100, chartData.data.cpi_nominal[1]*100, chartData.data.cpi_nominal[3]*100, chartData.data.cpi_nominal[4]*100, chartData.data.cpi_nominal_avg*100], whiskerColor: "white"},
    //       // { label: "Equity  real",  y: [chartData.data.equity_real[0]*100, chartData.data.equity_real[1]*100, chartData.data.equity_real[3]*100, chartData.data.equity_real[4]*100, chartData.data.equity_real_avg*100], whiskerColor: "white"},
    //       // { label: "Bond (10y) real",  y: [chartData.data.bond_real[0]*100, chartData.data.bond_real[1]*100, chartData.data.bond_real[3]*100, chartData.data.bond_real[4]*100, chartData.data.bond_real_avg*100], whiskerColor: "white"},
        
    //       // dataset with median as middle point
    //       { label: "Equity nominal",  y: [chartData.data.equity_nominal[0]*100, chartData.data.equity_nominal[1]*100, chartData.data.equity_nominal[9]*100, chartData.data.equity_nominal[10]*100, chartData.data.equity_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Bond (10y conventional) nominal",  y: [chartData.data.bond_nominal[0]*100, chartData.data.bond_nominal[1]*100, chartData.data.bond_nominal[9]*100, chartData.data.bond_nominal[10]*100, chartData.data.bond_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Inflation (CPI)",  y: [chartData.data.cpi_nominal[0]*100, chartData.data.cpi_nominal[1]*100, chartData.data.cpi_nominal[9]*100, chartData.data.cpi_nominal[10]*100, chartData.data.cpi_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Equity real",  y: [chartData.data.equity_real[0]*100, chartData.data.equity_real[1]*100, chartData.data.equity_real[9]*100, chartData.data.equity_real[10]*100, chartData.data.equity_real[5]*100], whiskerColor: "white"},
    //       { label: "Bond (10y conventional) real",  y: [chartData.data.bond_real[0]*100, chartData.data.bond_real[1]*100, chartData.data.bond_real[9]*100, chartData.data.bond_real[10]*100, chartData.data.bond_real[5]*100], whiskerColor: "white"},
    //     ]
    //   }]
    // };

    // const options = {
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   theme: "dark2",
    //   backgroundColor: "#212529",
    //   animationEnabled: true,
    //   axisX: {
    //     labelFontSize: my_font_size, 
    //   },
    //   axisY: {
    //     title: "% Annual Return",
    //     titleFontSize: my_font_size,
    //     labelFontSize: my_font_size,
    //   },
    //   toolTipContent: "<b>{label}</b><br/>Min: {y[0]}%<br/>10th Percentile: {y[1]}%<br/>50th Percentile: {y[2]}%<br/>90th Percentile: {y[3]}%<br/>Max: {y[4]}%",
    //   data: [{
    //     type: "boxAndWhisker",
    //     yValueFormatString: "#,##0.# \"%\"",
    //     dataPoints: [
    //       { label: "Equity nominal",  y: [chartData.data.equity_nominal[0]*100, chartData.data.equity_nominal[1]*100, chartData.data.equity_nominal[9]*100, chartData.data.equity_nominal[10]*100, chartData.data.equity_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Bond (10y conventional) nominal",  y: [chartData.data.bond_nominal[0]*100, chartData.data.bond_nominal[1]*100, chartData.data.bond_nominal[9]*100, chartData.data.bond_nominal[10]*100, chartData.data.bond_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Inflation (CPI)",  y: [chartData.data.cpi_nominal[0]*100, chartData.data.cpi_nominal[1]*100, chartData.data.cpi_nominal[9]*100, chartData.data.cpi_nominal[10]*100, chartData.data.cpi_nominal[5]*100], whiskerColor: "white"},
    //       { label: "Equity real",  y: [chartData.data.equity_real[0]*100, chartData.data.equity_real[1]*100, chartData.data.equity_real[9]*100, chartData.data.equity_real[10]*100, chartData.data.equity_real[5]*100], whiskerColor: "white"},
    //       { label: "Bond (10y conventional) real",  y: [chartData.data.bond_real[0]*100, chartData.data.bond_real[1]*100, chartData.data.bond_real[9]*100, chartData.data.bond_real[10]*100, chartData.data.bond_real[5]*100], whiskerColor: "white"},
    //     ]
    //   }]
    // };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      theme: "dark2",
      backgroundColor: "#212529",
      animationEnabled: true,
      axisX: {
        labelFontSize: my_font_size, 
      },
      axisY: {
        title: "% Annual Return",
        titleFontSize: my_font_size,
        labelFontSize: my_font_size,
      },
      data: [{
        type: "boxAndWhisker",
        yValueFormatString: "#,##0.# \"%\"",
        toolTipContent: "<b>{label}</b><br/>Min: {y[0]}<br/>10th Percentile: {y[1]}<br/>Median: {y[4]}<br/>90th Percentile: {y[2]}<br/>Max: {y[3]}<br/>",
        dataPoints: [
          { label: "Equity Nominal",  y: [chartData.data.equity_nominal[0]*100, chartData.data.equity_nominal[1]*100, chartData.data.equity_nominal[9]*100, chartData.data.equity_nominal[10]*100, chartData.data.equity_nominal[5]*100], whiskerColor: "white"},
          { label: "Bond (10y Conventional) Nominal",  y: [chartData.data.bond_nominal[0]*100, chartData.data.bond_nominal[1]*100, chartData.data.bond_nominal[9]*100, chartData.data.bond_nominal[10]*100, chartData.data.bond_nominal[5]*100], whiskerColor: "white"},
          { label: "Inflation (CPI)",  y: [chartData.data.cpi_nominal[0]*100, chartData.data.cpi_nominal[1]*100, chartData.data.cpi_nominal[9]*100, chartData.data.cpi_nominal[10]*100, chartData.data.cpi_nominal[5]*100], whiskerColor: "white"},
          { label: "Equity Real",  y: [chartData.data.equity_real[0]*100, chartData.data.equity_real[1]*100, chartData.data.equity_real[9]*100, chartData.data.equity_real[10]*100, chartData.data.equity_real[5]*100], whiskerColor: "white"},
          { label: "Bond (10y Conventional) Real",  y: [chartData.data.bond_real[0]*100, chartData.data.bond_real[1]*100, chartData.data.bond_real[9]*100, chartData.data.bond_real[10]*100, chartData.data.bond_real[5]*100], whiskerColor: "white"},
        ]
      }]
    };

    return (
        <>
        {isSmallScreen ? (
          <div style={{ width: '100%', height: '400px' }}>
            <CanvasJSChart options = {options} />
          </div>
        ) : (
          <div style={{ width: '100%', height: '400px' }}>
            <CanvasJSChart options = {options} />
          </div>
        )}
        
        </>
      );
  };

  export default BoxAndWhiskerPlot;

