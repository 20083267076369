import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'; // Import Modal from React-Bootstrap
import { register } from '../utils/auth';
import { useAuthStore } from '../store/auth';
import useLogin from '../utils/login';
import ButtonExample from "../components/loadingbutton.js";

const RegisterForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessageUserName, setErrorMessageUserName] = useState(''); 
    const [errorMessagePassword, setErrorMessagePassword] = useState(''); 
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [errorModal, setErrorModal] = useState(false); // State to control error modal visibility
    const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for checkbox
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
    const [loading, setLoading] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Processing...')

    const { login } = useLogin();

    // permittedUser control is removed.  Can add names here and add back the condition below.
    const permittedUsers = [];

    useEffect(() => {
        if (isLoggedIn()) {
            onClose(); // Close the modal if already logged in
        }
    }, [isLoggedIn, onClose]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
        setPassword2('');
        setErrorMessageUserName(''); // Reset the error message
        setErrorMessagePassword(''); // Reset the error message
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        // if (permittedUsers.includes(username)) {
            setLoading(true);
            const response = await register(username, password, password2);
            setLoading(false);

            if (response?.error) {
                const { error } = response;
                if (error.username) {
                    setErrorMessageUserName(error.username);
                } else if (error.password) {
                    setErrorMessagePassword(error.password);
                } else {
                    // Handle any other errors
                    resetForm();
                    setErrorModal(true); // Show the error modal
                }
            } else {
                // Successful registration, show modal and inform user to check email
                resetForm();
                setShowModal(true); // Show the success modal
            }
        // } else {
        //     setErrorMessageUserName('RetireSmartCalc is currently in beta testing. If you would like access, please email contact@retiresmartcalc.com');
        // }
    };

    // Close the success modal
    const handleCloseModal = () => {
        setShowModal(false);
        onClose(); // Close the form modal after closing the success modal
    };

    // Close the error modal
    const handleCloseErrorModal = () => {
        setErrorModal(false);
    };

    return (
        <>
            <section>
                <Form onSubmit={handleRegister}>
                    <Form.Group>
                        <Form.Label htmlFor="username" className="small">Email (will be your username):</Form.Label>
                        <Form.Control 
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="password" className="small">Password:</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}                
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="confirmPassword" className="small">Confirm Password:</Form.Label>
                        <Form.Control
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}                
                        />
                    </Form.Group>
                    {errorMessageUserName && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessageUserName}
                        </div>
                    )}
                    {errorMessagePassword && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessagePassword}
                        </div>
                    )}
                    
                    {/* Checkbox for agreeing to terms and conditions */}
                    <Form.Group controlId="termsCheckbox" className="mt-3">
                        <Form.Check 
                            type="checkbox"
                            label="I have read and agree to the Terms of Service."
                            checked={agreedToTerms}
                            onChange={() => setAgreedToTerms(!agreedToTerms)}
                        />
                    </Form.Group>

                    <br />
                    {loading ? (
                        <ButtonExample data={buttonlabel} />
                    ) : (
                        <Button 
                            variant="success" 
                            type="submit" 
                            disabled={!agreedToTerms} // Disable button if not agreed to terms
                        >
                            Register
                        </Button>
                    )}
                </Form>
            </section>

            {/* Modal to show after successful registration */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Registration Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    A verification link has been sent to your email. Please check your inbox to activate your account.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Error modal to show in case of unhandled error */}
            <Modal show={errorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Something went wrong. Contact administrator if issue persists.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RegisterForm;




// import React, { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal'; // Import Modal from React-Bootstrap
// import { register } from '../utils/auth';
// import { useAuthStore } from '../store/auth';
// import useLogin from '../utils/login';
// import ButtonExample from "../components/loadingbutton.js";

// const RegisterForm = ({ onClose }) => {
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [password2, setPassword2] = useState('');
//     const [errorMessageUserName, setErrorMessageUserName] = useState(''); 
//     const [errorMessagePassword, setErrorMessagePassword] = useState(''); 
//     const [showModal, setShowModal] = useState(false); // State to control modal visibility
//     const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for checkbox
//     const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
//     const [loading, setLoading] = useState(false);
//     const [buttonlabel, setButtonlabel] = useState('Processing...')

//     const { login } = useLogin();

//     const permittedUsers = ['toby.pittaway@gmail.com', 'toby.pittaway@googlemail.com', 'toby.pittaway@icloud.com'];

//     useEffect(() => {
//         if (isLoggedIn()) {
//             onClose(); // Close the modal if already logged in
//         }
//     }, [isLoggedIn, onClose]);

//     const resetForm = () => {
//         setUsername('');
//         setPassword('');
//         setPassword2('');
//         setErrorMessageUserName(''); // Reset the error message
//         setErrorMessagePassword(''); // Reset the error message
//     };

//     const handleRegister = async (e) => {
//         e.preventDefault();
//         // resetForm(); // Clear all error messages before proceeding
//         if (permittedUsers.includes(username)) {

//             setLoading(true);
//             const response = await register(username, password, password2);
//             setLoading(false);

//             if (response?.error) {
//                 const { error } = response;
//                 if (error.username) {
//                     setErrorMessageUserName(error.username);
//                 } else if (error.password) {
//                     setErrorMessagePassword(error.password);
//                 }
//             } else {
//                 // Successful registration, show modal and inform user to check email
//                 resetForm();
//                 setShowModal(true); // Show the success modal
//             }
//         } else {
//             setErrorMessageUserName('Contact administrator for access.');
//         }
//     };

//     // Close the modal and the parent form
//     const handleCloseModal = () => {
//         setShowModal(false);
//         onClose(); // Close the form modal after closing the success modal
//     };

//     return (
//         <>
//             <section>
//                 <Form onSubmit={handleRegister}>
//                     <Form.Group>
//                         <Form.Label htmlFor="username" className="small">Email (will be your username):</Form.Label>
//                         <Form.Control 
//                             type="text"
//                             id="username"
//                             name="username"
//                             value={username}
//                             onChange={(e) => setUsername(e.target.value)}
//                         />
//                     </Form.Group>
//                     <Form.Group>
//                         <Form.Label htmlFor="password" className="small">Password:</Form.Label>
//                         <Form.Control
//                             type="password"
//                             id="password"
//                             name="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}                
//                         />
//                     </Form.Group>
//                     <Form.Group>
//                         <Form.Label htmlFor="confirmPassword" className="small">Confirm Password:</Form.Label>
//                         <Form.Control
//                             type="password"
//                             id="confirmPassword"
//                             name="confirmPassword"
//                             value={password2}
//                             onChange={(e) => setPassword2(e.target.value)}                
//                         />
//                     </Form.Group>
//                     {errorMessageUserName && (
//                         <div style={{ color: 'red', marginTop: '10px' }}>
//                             {errorMessageUserName}
//                         </div>
//                     )}
//                     {errorMessagePassword && (
//                         <div style={{ color: 'red', marginTop: '10px' }}>
//                             {errorMessagePassword}
//                         </div>
//                     )}
                    
//                     {/* Checkbox for agreeing to terms and conditions */}
//                     <Form.Group controlId="termsCheckbox" className="mt-3">
//                         <Form.Check 
//                             type="checkbox"
//                             label="I have read and agree to the Terms of Service."
//                             checked={agreedToTerms}
//                             onChange={() => setAgreedToTerms(!agreedToTerms)}
//                         />
//                     </Form.Group>

//                     <br />
//                     {loading ? (
//                         <ButtonExample data={buttonlabel} />
//                     ) : (
//                         <Button 
//                             variant="success" 
//                             type="submit" 
//                             disabled={!agreedToTerms} // Disable button if not agreed to terms
//                             >
//                             Register
//                         </Button>
//                     )}
//                 </Form>
//             </section>

//             {/* Modal to show after successful registration */}
//             <Modal show={showModal} onHide={handleCloseModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Registration Successful</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     A verification link has been sent to your email. Please check your inbox to activate your account.
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="success" onClick={handleCloseModal}>
//                         OK
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// };

// export default RegisterForm;
