import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

const EmailVerification = () => {
  const { uidb64, token } = useParams(); // Extract uidb64 and token from URL params
  const navigate = useNavigate(); // For redirecting the user
  const [message, setMessage] = useState(''); // To display the activation message
  const [error, setError] = useState(''); // To display any errors
  const [isLoading, setIsLoading] = useState(true); // Loading state to manage UI during request

  // Send uidb64 and token to the backend API to verify the email
useEffect(() => {
const verifyEmail = async () => {
    try {
    const response = await axios.post('/api/activate/', { uidb64, token });

    if (response.status === 200) {
        setMessage('Your account has been successfully activated.');
    } else {
        setError('Failed to activate your account. Please try again.');
    }
    } catch (err) {
    setError('Activation link is invalid or has expired.');
    } finally {
    setIsLoading(false); // Once request is complete, stop loading
    }
};
verifyEmail();
}, [uidb64, token]);

  // Handle button click to redirect the user to the homepage
const handleConfirm = () => {
// navigate('/'); // Redirect user to the homepage
// window.close(); // Close the browser window
try {
    window.close();
  } catch (e) {
    setMessage('You can now manually close this tab.');
  }
};

return (
<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>

<div className="row">
<div className="col-sm-2"></div>
<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
    {isLoading ? (
    <p>Activating your account...</p>
    ) : error ? (
    <Alert variant="danger">{error}</Alert>
    ) : (
    <Alert variant="success">{message}</Alert>
    )}

    {!isLoading && (
    <Button variant="secondary" onClick={handleConfirm}>
        Close
    </Button>
    )}
</div>
<div className="col-sm-2"></div>
</div>
</Container>
);
};

export default EmailVerification;

